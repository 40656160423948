import React from 'react'
import { Button, Table } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import API from './Components/Global_API'
import Router from './Router';
import moment from 'moment';
import NoInternet from './Components/NoInternet';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

const styles = {
    inputDiv: {
        paddingBottom: 10
    },
    inputField: {
        height: 40
    }
}

export default class Report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            start_date: null,
            end_date: null,
            learner_type_data: [],
            course_data: [],
            payment_plan_data: [],
            assessor_data: [],
            all_learner_data: [],
            data: [],
            search_learner_type: '',
            search_course: '',
            search_status: '',
            search_payment_plan: '',
            search_assessor: '',
            filter_by_learner_type: [],
            filter_by_course: [],
            filter_by_assessor: [],
            search_learner: '',
            filter_by_learner: [],
            filter_by_payment_plan: [],
            filter_by_start_date: [],
            filter_by_end_date: [],
            filter_by_status: [],
            isLoader: true
        }

        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    handleIndividualLearner = (item) => {
        this.props.history.push('/individual_learner', { learner_id: item.id })
    }

    handleStartDate = (e) => {
        this.setState({ start_date: e })
        let learner = this.state.all_learner_data
        if (e === '') {
            this.setState({ filter_by_start_date: this.state.all_learner_data })
        } else {
            let filterdIqa = learner.filter((item) => {
                return item.result_generate_date >= moment(e).format('DD/MM/YYYY')
            });

            this.setState({ filter_by_start_date: filterdIqa });
        }
    }

    handleEndDate = (e) => {
        this.setState({ end_date: e })

        let learner = this.state.all_learner_data
        if (e === '') {
            this.setState({ filter_by_end_date: this.state.all_learner_data })
        } else {
            let filterdIqa = learner.filter((item) => {
                return item.result_generate_date <= moment(e).format('DD/MM/YYYY')
            });
            this.setState({ filter_by_end_date: filterdIqa });
        }
    }

    handleFilterByLearner = (e) => {
        this.setState({ search_learner: e.target.value })
        let learner = this.state.all_learner_data
        let filter_data = []
        if (e.target.value === '') {
            this.setState({ filter_by_learner: this.state.all_learner_data })
            filter_data = this.state.all_learner_data
        } else {
            let filterdIqa = learner.filter((item) => {
                return (item.first_name.toLowerCase() + ' ' + item.middle_name.toLowerCase() + ' ' + item.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) || item.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.middle_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.last_name.toLowerCase().includes(e.target.value.toLowerCase());
            });
            filter_data = filterdIqa
            this.setState({ filter_by_learner: filterdIqa });
        }
        Array.prototype.intersection = function (arr) {
            return this.filter(function (e) {
                return arr.indexOf(e) > -1;
            });
        };

        let all_filter_data = this.state.filter_by_learner_type.intersection(this.state.filter_by_course.intersection(this.state.filter_by_assessor.intersection(filter_data.intersection(this.state.filter_by_payment_plan.intersection(this.state.filter_by_start_date.intersection(this.state.filter_by_end_date.intersection(this.state.filter_by_status)))))))
        this.setState({ data: all_filter_data })
    }

    handleExport = async (e) => {

        const data = this.state.data.map(row => ({
            learner_no: row.candidate_no,
            name: row.first_name + ' ' + row.middle_name + ' ' + row.last_name,
            registered_date: row.date_registered,
            course: row.course_name,
            type: row.learner_type,
            AO: row.ao_name,
            assessor_name: row.assessor_name,
            payment_plan: row.payment_plan,
            iqa: row.iqa_name,
            result_generate_date: row.result_generate_date,
            pass_fail_status: row.pass_fail_status,
        }))
        const csvData = this.objectToCsv(data)
        this.download(csvData);


        // // start convert pdf code
        // const filename = 'ThisIsYourPDFFilename.pdf';

        // html2canvas(document.querySelector('#capture')).then(canvas => {
        //     let pdf = new jsPDF('p', 'mm', 'a4');
        //     pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
        //     pdf.save(filename);
        // });
        // // end convert pdf code
    }

    objectToCsv(data) {
        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            })
            csvRows.push(values.join(','));
        }
        return csvRows.join('\n');
    }

    download(data) {
        const blod = new Blob([data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blod);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'download.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    componentDidMount() {
        fetch(API + 'display_learner_report_list.php').then((response) => response.json()).then((responseJsonFromServer) => {
            this.setState({ isLoader: false })
            if (responseJsonFromServer.result == "success") {
                this.setState({
                    data: responseJsonFromServer.learner_data,
                    all_learner_data: responseJsonFromServer.learner_data,
                    filter_by_learner_type: responseJsonFromServer.learner_data,
                    filter_by_course: responseJsonFromServer.learner_data,
                    filter_by_assessor: responseJsonFromServer.learner_data,
                    filter_by_learner: responseJsonFromServer.learner_data,
                    filter_by_payment_plan: responseJsonFromServer.learner_data,
                    filter_by_start_date: responseJsonFromServer.learner_data,
                    filter_by_end_date: responseJsonFromServer.learner_data,
                    filter_by_status: responseJsonFromServer.learner_data,
                })
            }

        }).catch((error) => {
            if (error.message == 'Failed to fetch') {
                this.setState({ networkError: true })
            }
        });
        fetch(API + 'get_all_data.php').then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {
                this.setState({
                    learner_type_data: responseJsonFromServer.learner_type_data,
                    payment_plan_data: responseJsonFromServer.payment_plans_data
                })

            }

        }).catch((error) => {
            if (error.message == 'Failed to fetch') {
                this.setState({ networkError: true })
            }
        });

        let role = JSON.stringify({
            role: "assessor"
        })
        fetch(API + 'get_all_users.php', {
            method: 'POST',
            body: role
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {
                this.setState({ assessor_data: responseJsonFromServer.users_data, })
            } else {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                x.innerHTML = responseJsonFromServer.msg;
            }

        }).catch((error) => {
            if (error.message == 'Failed to fetch') {
                this.setState({ networkError: true })
            }
        });

        // course data //
        fetch(API + 'display_course.php')
            .then(res => res.json())
            .then((course) => {

                if (course.result === 'success') {
                    this.setState({ course_data: course.course_data })
                }

            }).catch((error) => {
                console.log(error.message);
            });

    }

    handleSearchLearnerType = (e) => {
        this.setState({ search_learner_type: e.target.value })
        let learner = this.state.all_learner_data

        if (e.target.value === '') {
            this.setState({ filter_by_learner_type: this.state.all_learner_data })
        } else {
            let filter_learner_type = learner.filter((item) => {
                return item.learner_type_id == e.target.value;
            });
            this.setState({ filter_by_learner_type: filter_learner_type })
        }
    }

    handleSearchCourseType = (e) => {
        this.setState({ search_course: e.target.value })

        let learner = this.state.all_learner_data

        if (e.target.value === '') {
            this.setState({ filter_by_course: this.state.all_learner_data })
        } else {
            let filter_course = learner.filter((item) => {
                return item.course_id == e.target.value;
            });

            this.setState({ filter_by_course: filter_course })
        }
    }

    handleSearchStatus = (e) => {
        this.setState({ search_status: e.target.value })
        let learner = this.state.all_learner_data

        if (e.target.value === '') {
            this.setState({ filter_by_status: this.state.all_learner_data })
        } else {
            let filter_course = learner.filter((item) => {
                return item.pass_fail_status.toLowerCase() == e.target.value.toLowerCase();
            });
            this.setState({ filter_by_status: filter_course })
        }
    }

    handleSearchPaymentPlan = (e) => {
        this.setState({ search_payment_plan: e.target.value })
        let learner = this.state.all_learner_data

        if (e.target.value === '') {
            this.setState({ filter_by_payment_plan: this.state.all_learner_data })
        } else {
            let filter_assessor = learner.filter((item) => {
                return item.payment_plan == e.target.value;
            });
            this.setState({ filter_by_payment_plan: filter_assessor })
        }
    }

    handleSearchAssessor = (e) => {
        this.setState({ search_assessor: e.target.value })
        let learner = this.state.all_learner_data

        if (e.target.value === '') {
            this.setState({ filter_by_assessor: this.state.all_learner_data })
        } else {
            let filter_assessor = learner.filter((item) => {
                return item.accessor_id == e.target.value;
            });
            this.setState({ filter_by_assessor: filter_assessor })
        }
    }

    handleApplyFilter = () => {

        if (this.state.search_learner_type.length == 0 && this.state.search_course.length == 0 && this.state.search_payment_plan == 0 && this.state.search_assessor == 0 && this.state.start_date == null && this.state.end_date == null && this.state.search_status.length == 0) {
            this.setState({ data: this.state.all_learner_data })
        } else {
            Array.prototype.intersection = function (arr) {
                return this.filter(function (e) {
                    return arr.indexOf(e) > -1;
                });
            };

            let filter_data = this.state.filter_by_learner_type.intersection(this.state.filter_by_course.intersection(this.state.filter_by_assessor.intersection(this.state.filter_by_learner.intersection(this.state.filter_by_payment_plan.intersection(this.state.filter_by_start_date.intersection(this.state.filter_by_end_date.intersection(this.state.filter_by_status)))))))
            this.setState({ data: filter_data })
        }
    }

    handleAllClearFilter = () => {
        this.setState({
            learner_type_data: this.state.all_learner_data,
            data: this.state.all_learner_data,
            search_learner_type: '',
            search_course: '',
            search_status: '',
            search_payment_plan: '',
            search_assessor: '',
            start_date: '',
            end_date: '',
            filter_by_learner_type: this.state.all_learner_data,
            filter_by_course: this.state.all_learner_data,
            filter_by_assessor: this.state.all_learner_data,
            search_learner: '',
            filter_by_learner: this.state.all_learner_data,
            filter_by_payment_plan: this.state.all_learner_data,
            filter_by_start_date: this.state.all_learner_data,
            filter_by_end_date: this.state.all_learner_data,
            filter_by_status: this.state.all_learner_data,
        })
    }


    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            return (
                <div>
                    <Router />
                    <div style={{ backgroundColor: '#EEF2F6', minHeight: '100vh', overflowY: 'auto' }}>
                        <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingLeft: 20, paddingTop: 80, paddingRight: 20, paddingBottom: 20 }}>
                            <div>
                                <div className="row" style={{ marginTop: -10, marginBottom: -10, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <div className="col-12" >
                                        <div className="row" >
                                            <div className="dropdown-filter">
                                                <h6>Learner type</h6>
                                                <select className="form-control" value={this.state.search_learner_type} onChange={this.handleSearchLearnerType} style={styles.inputField}>
                                                    <option value=''>Any</option>
                                                    {this.state.learner_type_data.map(item =>
                                                        <option key={item.learner_type_id} value={item.learner_type_id}>{item.learner_type}</option>
                                                    )}
                                                </select>
                                            </div>

                                            <div className="dropdown-filter">
                                                <h6>Course type</h6>

                                                <select className="form-control" value={this.state.search_course} onChange={this.handleSearchCourseType} style={styles.inputField}>
                                                    <option value=''>Any</option>
                                                    {this.state.course_data.map(item =>
                                                        <option key={item.course_id} value={item.course_id}>{item.course_name}</option>
                                                    )}
                                                </select>
                                            </div>

                                            <div className="dropdown-filter">
                                                <h6>Status</h6>
                                                <select className="form-control" value={this.state.search_status} onChange={this.handleSearchStatus} style={styles.inputField}>
                                                    <option value=''>Any</option>
                                                    <option value="Pass">Pass</option>
                                                    <option value="fail">Refer</option>
                                                    <option value="pending">Pending</option>
                                                </select>
                                            </div>
                                            <div className="dropdown-filter">
                                                <h6>Payment plan</h6>
                                                <select className="form-control" value={this.state.search_payment_plan} onChange={this.handleSearchPaymentPlan} style={styles.inputField}>
                                                    <option value=''>Any</option>
                                                    {this.state.payment_plan_data.map(item =>
                                                        <option key={item.payment_plans} value={item.payment_plans}>{item.payment_plans}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="dropdown-filter">
                                                <h6>Assessor</h6>
                                                <select className="form-control" value={this.state.search_assessor} onChange={this.handleSearchAssessor} style={styles.inputField} placeholder="Assessor" >
                                                    <option value=''>Any</option>
                                                    {this.state.assessor_data.map(item =>
                                                        <option key={item.id} value={item.id}> {item.email} </option>
                                                    )}
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="body-container" style={{ paddingTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <div className="col-lg-4 col-md-4 col-sm-12 pl-0 report-filter">
                                        <h6>Completed date range</h6>
                                        <div className="row">
                                            <div className="col-6">
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Start date"
                                                    dateFormat="dd/MM/yyyy"
                                                    locale={false}
                                                    maxDate={this.state.end_date}
                                                    showTimeInput={false}
                                                    selected={this.state.start_date}
                                                    onChange={this.handleStartDate}
                                                    id="start_date"
                                                />
                                            </div>
                                            <div className="col-6">
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="End date"
                                                    dateFormat="dd/MM/yyyy"
                                                    locale={false}
                                                    minDate={this.state.start_date}
                                                    showTimeInput={false}
                                                    selected={this.state.end_date}
                                                    onChange={this.handleEndDate}
                                                    id="end_date"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 pr-0 report-filter">
                                        <h6>Learner</h6>
                                        <input type="text" className="form-control" value={this.state.search_learner} onChange={this.handleFilterByLearner} />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 pr-0 report-filter" style={{ paddingTop: 28 }}>
                                        <div className="row">
                                            <div className="col-6" >
                                                <Button variant="primary" block onClick={this.handleApplyFilter}>Apply filter</Button>
                                            </div>
                                            <div className="col-6" >
                                                <Button variant="primary" block onClick={this.handleAllClearFilter}>Clear filter</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.isLoader ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 30 }}>
                                <img src={require('../assets/Loader.gif')} />
                            </div> :
                            this.state.data.length == 0 ? <div className="text-center pt-5"><h3>No data found</h3></div> :
                                <div>
                                    <div className="body-container" style={{ paddingTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <div className="col-12" >
                                            <Table responsive hover id="capture">
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Learner Name</th>
                                                        <th>Date registered</th>
                                                        <th>Course</th>
                                                        <th>Type</th>
                                                        <th>AO</th>
                                                        <th>Assessor</th>
                                                        <th>Payment plan</th>
                                                        <th>IQA</th>
                                                        <th>Date Completed</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data.map((item, i) =>
                                                        <tr key={i} onClick={() => this.handleIndividualLearner(item)} style={{ cursor: 'pointer' }}>
                                                            <td>{item.candidate_no}</td>
                                                            <td>{item.first_name + ' ' + item.middle_name + ' ' + item.last_name}</td>
                                                            <td>{item.date_registered == '' ? '-' : item.date_registered}</td>
                                                            <td>{item.course_name}</td>
                                                            <td>{item.learner_type}</td>
                                                            <td>{item.ao_name == '' ? '-' : item.ao_name}</td>
                                                            <td>{item.assessor_name == '' ? '-' : item.assessor_name}</td>
                                                            <td>{item.payment_plan}</td>
                                                            <td>{item.iqa_name == '' ? '-' : item.iqa_name}</td>
                                                            <td>{item.result_generate_date == '' ? '-' : item.result_generate_date}</td>
                                                            <td>{item.pass_fail_status == '' ? '-' : item.pass_fail_status == 'Fail' ? 'Refer' : item.pass_fail_status}</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="body-container" style={{ paddingTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: 40 }}>
                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                            <Button variant="primary" onClick={this.handleExport} block>Export CSV</Button>
                                        </div>
                                    </div>
                                </div>}
                    </div>
                </div>
            )
        }
    }
}