import React from 'react';
import Router from './Router'
import Icon from 'react-icons-kit';
import { plus } from 'react-icons-kit/entypo';
import { androidSend } from 'react-icons-kit/ionicons';
import { Modal, Badge } from 'react-bootstrap';
import API from './Components/Global_API'
import NoInternet from './Components/NoInternet';

const styles = {
    chat_title: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    content: {
        paddingTop: 10,
        paddingBottom: 5
    },
    profileImage: {
        fontFamily: 'Arial, Helvetica, sans-serif',
        width: 40,
        height: 40,
        borderRadius: '50%',
        background: 'rgb(67 124 158)',
        fontSize: 20,
        color: '#fff',
        textAlign: 'center',
        lineHeight: 2,
        margin: 5
    }
}

export default class Messages extends React.Component {
    constructor() {
        super();
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.state = {
            all_userList: false,
            role: '',
            search_user: '',
            user_data: [],
            all_user_data: [],
            admin_details: '',
            user_name: '',
            user_id: '',
            text: '',
            iqa_list: [],
            assessor_list: [],
            learner_list: [],
            without_filter_iqa: [],
            without_filter_assessor: [],
            without_filter_learner: [],
            users_chat: [],
            users: '',
            search_old_users: ''
        }
        if (localStorage.getItem("loginUser")) {
            this.state.admin_details = JSON.parse(localStorage.getItem("loginUser"))
        }
        let loginUser = localStorage.getItem("loginUser")

        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    handleAddNewIQA = () => {
        this.setState({ role: 'iqa', users: 'all iqa' }, () => this.handleAddNewUserPopupOpen())
    }

    handleAddNewAssessor = () => {
        this.setState({ role: 'assessor', users: 'all assessor' }, () => this.handleAddNewUserPopupOpen())
    }

    handleAddNewLearner = () => {
        this.setState({ role: 'learner', users: 'all learner' }, () => this.handleAddNewUserPopupOpen())
    }

    handleAddNewUserPopupOpen = () => {
        this.setState({ all_userList: true })

        let role = JSON.stringify({
            role: this.state.role
        })

        fetch(API + 'get_all_users.php', {
            method: 'POST',
            body: role
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            this.setState({ dataFound: true })
            let sortedData = responseJsonFromServer.users_data
            sortedData.sort((a, b) => (a.first_name + a.last_name).localeCompare(b.first_name + b.last_name))

            if (responseJsonFromServer.result == "success") {
                this.setState({ user_data: responseJsonFromServer.users_data, all_user_data: responseJsonFromServer.users_data })
            }

        }).catch((error) => {
            if (error.message == 'Failed to fetch') {
                this.setState({ networkError: true })
            }
        });
    }

    handleAddNewUserPopupClose = () => {
        this.setState({ all_userList: false, search_user: '' })
    }

    handleSearchUser = (e) => {
        this.setState({ search_user: e.target.value })

        let user_data = this.state.all_user_data;

        let filter_data = user_data.filter((item) => {
            return (item.first_name.toLowerCase() + ' ' + item.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) || item.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.middle_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.last_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.email.toLowerCase().includes(e.target.value.toLowerCase()) || item.contact.includes(e.target.value);
        });

        this.setState({ user_data: filter_data })

    }

    handleNewUserChat = (item) => {
        this.setState({
            all_userList: false,
            user_name: item.first_name + ' ' + item.last_name,
            user_id: item.id,
            search_user: '',
            text: ''
        }, () => this.chatted_history())
    }

    handleAllUserChat = () => {
        this.setState({
            all_userList: false,
            user_name: this.state.users,
            role: this.state.users,
            user_id: '',
            text: ''
        }, () => this.chatted_history())
    }

    handleOldUserChat = (item) => {
        this.setState({
            user_id: item.user_id,
            user_name: item.user_name,
            role: item.user_role,
            text: ''
        }, () => this.chatted_history())
    }

    handleSearchOldUsers = (e) => {
        this.setState({ search_old_users: e.target.value })



        if (this.state.search_old_users.length == 0) {
            this.setState({
                iqa_list: this.state.without_filter_iqa,
                assessor_list: this.state.without_filter_assessor,
                learner_list: this.state.without_filter_learner
            })
        } else {
            let iqa = this.state.without_filter_iqa
            let filterdIqa = iqa.filter((item) => {
                return (item.user_name.toLowerCase()).includes(e.target.value.toLowerCase());
            });

            let assessor = this.state.without_filter_assessor
            let filterdAssessor = assessor.filter((item) => {
                return (item.user_name.toLowerCase()).includes(e.target.value.toLowerCase());
            });

            let learner = this.state.without_filter_learner
            let filterdLearner = learner.filter((item) => {
                return (item.user_name.toLowerCase()).includes(e.target.value.toLowerCase());
            });

            this.setState({
                iqa_list: filterdIqa,
                assessor_list: filterdAssessor,
                learner_list: filterdLearner,
            })

        }

    }

    chatted_history = () => {

        let details = JSON.stringify({
            sender_id: this.state.admin_details.userId,
            receiver_id: this.state.user_id,
            receiver_role: this.state.role,
            sender_role: 'admin'
        })
        console.log("details", details);
        fetch(API + 'user_chatted_history.php', {
            method: 'POST',
            body: details
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {
                this.setState({
                    users_chat: responseJsonFromServer.users_chat
                })
                this.chatted_user()
            }
        }).catch((error) => {
            if (error.message == 'Failed to fetch') {
                this.setState({ networkError: true })
            }
        });


    }

    handleMessage = (e) => {
        this.setState({
            text: e.target.value
        })
    }

    chatted_user = () => {
        let users = JSON.stringify({
            sender_id: this.state.admin_details.userId
        })
        fetch(API + 'chatted_users_list_new.php', {
            method: 'POST',
            body: users
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {
                console.log("responseJsonFromServer", responseJsonFromServer);
                let iqa = [];
                let assessor = [];
                let learner = [];

                responseJsonFromServer.chat_users.map(item => {
                    if (item.user_role == 'assessor') {
                        assessor.push(item)
                    } else if (item.user_role == 'iqa') {
                        iqa.push(item)
                    } else if (item.user_role == 'learner') {
                        learner.push(item)
                    }
                })

                this.setState({
                    iqa_list: iqa,
                    assessor_list: assessor,
                    learner_list: learner,
                    without_filter_iqa: iqa,
                    without_filter_assessor: assessor,
                    without_filter_learner: learner
                })

            }

        }).catch((error) => {
            if (error.message == 'Failed to fetch') {
                this.setState({ networkError: true })
            }
        });
    }

    scrollToBottom = () => {
        this.messagesEnd && this.messagesEnd.scrollIntoView({ behavior: "auto", block: "end", inline: "nearest" });
    }

    componentDidMount() {
        this.scrollToBottom();
        this.chatted_user();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    handleChat = () => {
        if (this.state.text !== '') {
            let chat = JSON.stringify({
                receiver_id: this.state.user_id,
                sender_id: this.state.admin_details.userId,
                text: this.state.text,
                receiver_role: this.state.role,
                sender_role: 'admin'
            })

            fetch(API + 'send_message.php', {
                method: 'POST',
                body: chat
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                if (responseJsonFromServer.result == "success") {
                    this.chatted_history()
                    this.setState({ text: '', search_old_users: '' })
                    this.chatted_user()
                }

            }).catch((error) => {
                if (error.message == 'Failed to fetch') {
                    this.setState({ networkError: true })
                }
            });
        }

    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            return (
                <div style={{ backgroundColor: '#fff', width: '100%', minHeight: '100vh', overflowX: 'hidden' }}>
                    <Router />
                    <div className="row" style={{ borderBottom: '1px solid #999', height: 130, width: '100%', position: 'fixed', zIndex: 2, backgroundColor: '#fff' }}>
                        <div className="col-3 pl-4" style={{ borderRight: '1px solid #999', height: '100%', paddingTop: 70, paddingBottom: 15 }}>
                            <input type="text" className="form-control" placeholder="Search by IQA, assessor and learner" value={this.state.search_old_users} onChange={this.handleSearchOldUsers} />
                        </div>
                        <div className="col-9" style={{ height: '100%', paddingTop: 70 }}>
                            <h4 style={{ textTransform: 'capitalize' }}>{this.state.user_name.toLowerCase()}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 pl-4" style={{ borderRight: '1px solid #999', height: '100%', width: '100%', position: 'fixed', paddingTop: 140, overflowY: 'scroll', zIndex: 1 }}>
                            <div style={styles.content}>
                                <div style={styles.chat_title}>
                                    <h5>IQA</h5>
                                    <Icon icon={plus} size={30} style={{ float: 'right' }} onClick={this.handleAddNewIQA} />
                                </div>
                                <div>
                                    {this.state.iqa_list.length == 0 ? <div><h6>No user found</h6></div> :

                                        this.state.iqa_list.map(item =>
                                            <div key={item.user_id} onClick={() => this.handleOldUserChat(item)} style={{ cursor: 'pointer', borderBottom: '1px solid rgba(153, 153, 152, 0.43)', flexDirection: 'row', display: 'flex' }}>
                                                {item.profile_image == '' ? <div style={styles.profileImage}>
                                                    {item.user_name.split(' ').map(name => name[0]).join('').toUpperCase()}
                                                </div> : <img src={item.profile_image} style={styles.profileImage} />}
                                                <div style={{ flexDirection: 'column', display: 'flex', paddingTop: 5, width: 215 }}>
                                                    <div className="d-flex row m-0 p-0 justify-content-between">
                                                        <h6 style={{ textTransform: 'capitalize', marginBottom: -3, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.user_name.toLowerCase()}</h6>
                                                        {item.message_count ? <Badge variant="primary" style={{ marginBottom: -3 }}>{item.message_count}</Badge> : null}
                                                    </div>
                                                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.latest_message}</span>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div style={styles.content}>
                                <div style={styles.chat_title}>
                                    <h5>Assessor</h5>
                                    <Icon icon={plus} size={30} style={{ float: 'right' }} onClick={this.handleAddNewAssessor} />
                                </div>
                                <div>
                                    {this.state.assessor_list.length == 0 ? <div><h6>No user found</h6></div> :
                                        this.state.assessor_list.map(item =>
                                            <div key={item.user_id} onClick={() => this.handleOldUserChat(item)} style={{ cursor: 'pointer', borderBottom: '1px solid rgba(153, 153, 152, 0.43)', flexDirection: 'row', display: 'flex' }}>
                                                {item.profile_image == '' ? <div style={styles.profileImage}>
                                                    {item.user_name.split(' ').map(name => name[0]).join('').toUpperCase()}
                                                </div> : <img src={item.profile_image} style={styles.profileImage} />}
                                                <div style={{ flexDirection: 'column', display: 'flex', paddingTop: 5, width: 215 }}>
                                                    <div className="d-flex row m-0 p-0 justify-content-between">
                                                        <h6 style={{ textTransform: 'capitalize', marginBottom: -3, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.user_name.toLowerCase()}</h6>
                                                        {item.message_count ? <Badge variant="primary" style={{ marginBottom: -3 }}>{item.message_count}</Badge> : null}
                                                    </div>
                                                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.latest_message}</span>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div style={styles.content}>
                                <div style={styles.chat_title}>
                                    <h5>Learner</h5>
                                    <Icon icon={plus} size={30} style={{ float: 'right' }} onClick={this.handleAddNewLearner} />
                                </div>
                                <div>
                                    {this.state.learner_list.length == 0 ? <div><h6>No user found</h6></div> :
                                        this.state.learner_list.map(item =>
                                            <div key={item.user_id} onClick={() => this.handleOldUserChat(item)} style={{ cursor: 'pointer', borderBottom: '1px solid rgba(153, 153, 152, 0.43)', flexDirection: 'row', display: 'flex', overflow: 'hidden' }}>
                                                {item.profile_image == '' ? <div style={styles.profileImage}>
                                                    {item.user_name.split(' ').map(name => name[0]).join('').toUpperCase()}
                                                </div> : <img src={item.profile_image} style={styles.profileImage} />}
                                                <div style={{ flexDirection: 'column', display: 'flex', paddingTop: 5, width: 215 }}>
                                                    <div className="d-flex row m-0 p-0 justify-content-between">
                                                        <h6 style={{ textTransform: 'capitalize', marginBottom: -3, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.user_name.toLowerCase()}</h6>
                                                        {item.message_count ? <Badge variant="primary" style={{ marginBottom: -3 }}>{item.message_count}</Badge> : null}
                                                    </div>
                                                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.latest_message}</span>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>


                        <Modal size='lg' show={this.state.all_userList} onHide={this.handleAddNewUserPopupClose}>
                            <Modal.Header closeButton style={{ borderBottom: 'none' }}>
                                <input type="text" className="form-control" placeholder="Search user" value={this.state.search_user} onChange={this.handleSearchUser} />
                            </Modal.Header>
                            <Modal.Body style={{ height: 700, overflowY: "auto" }}>
                                {this.state.user_data.length == 0 ? <div className="text-center"><h4>No user found</h4></div> :
                                    <><div style={{ padding: '10px 0px', borderBottom: '1px solid rgba(153, 153, 152, 0.43)', cursor: 'pointer', flexDirection: 'row', display: 'flex' }} onClick={() => this.handleAllUserChat()}>
                                        <div style={styles.profileImage}>
                                            {this.state.users.split(' ').map(name => name[0]).join('').toUpperCase()}
                                        </div>
                                        <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', paddingTop: 5 }}>
                                            <h6 style={{ textTransform: 'capitalize' }}>{this.state.users}</h6>
                                        </div>
                                    </div>
                                        {this.state.user_data.map(item =>
                                            <div key={item.id} style={{ padding: '10px 0px', borderBottom: '1px solid rgba(153, 153, 152, 0.43)', cursor: 'pointer', flexDirection: 'row', display: 'flex' }} onClick={() => this.handleNewUserChat(item)}>
                                                {item.profile_image == '' ?
                                                    <div style={styles.profileImage}>
                                                        {item.first_name[0].toUpperCase() + item.last_name[0].toUpperCase()}
                                                    </div> : <img src={item.profile_image} style={styles.profileImage} />}
                                                <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', paddingTop: 5 }}>
                                                    <h6 style={{ textTransform: 'capitalize' }}>{item.first_name.toLowerCase() + ' ' + item.last_name.toLowerCase()}</h6>
                                                </div>

                                            </div>
                                        )}
                                    </>
                                }
                            </Modal.Body>
                        </Modal>



                        <div className="col-9" >

                            <div style={{ borderRight: '1px solid #999', height: '100%', width: '75%', marginLeft: '25%', position: 'fixed', paddingTop: 140, overflowY: 'scroll', scrollTop: 'scrollHeight' }}>
                                {!this.state.user_id && this.state.user_name == '' ?
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center' }}>
                                        <img src={require('../assets/pta-logo2.png')} style={{ width: '30%', height: '30%' }} />
                                    </div> :
                                    <div style={{ marginRight: 15 }}>
                                        <div style={{ paddingBottom: 80, height: '100%' }} ref={(el) => { this.messagesEnd = el; }}>
                                            {this.state.users_chat.map((item, i) =>
                                                <div key={i} style={{ display: 'flex', maxWidth: '100%', margin: 5, flexDirection: item.sender_role == 'admin' ? 'row-reverse' : 'row' }}>
                                                    <div>
                                                        <div style={{ width: '100%' }}>
                                                            <small>{item.date_time_sent}</small>
                                                        </div>
                                                        <div style={{ padding: 10, backgroundColor: 'rgb(230, 235, 240)', maxWidth: '100%', borderRadius: 4 }}>
                                                            <span>{item.message}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                        <div style={{ padding: '20px 30px 20px 0', position: 'fixed', bottom: 0, width: '75%', backgroundColor: '#fff' }}>
                                            <div style={{ display: 'flex' }}>
                                                <input type="text" className="form-control" placeholder="Text message" value={this.state.text} onChange={this.handleMessage} />
                                                <Icon icon={androidSend} size={30} style={{ padding: 5, color: '#fff', backgroundColor: '#14B4F0', marginLeft: 10, borderRadius: 30 }} onClick={this.handleChat} />
                                            </div>
                                        </div>
                                    </div>}
                            </div>

                        </div>
                    </div>
                </div>

            )
        }
    }
}