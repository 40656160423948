import React from 'react';
import { Accordion, Button, Card, Table, ProgressBar } from 'react-bootstrap';
import { Icon } from 'react-icons-kit';
import { cross } from 'react-icons-kit/icomoon/'
import API from '../Components/Global_API'
import Router from '../Router';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment';
import NoInternet from '../Components/NoInternet';

const styles = {
    inputDiv: {
        paddingBottom: 10
    },
    inputField: {
        height: 40
    },
    inputAssessor_nameList: {
        padding: 10,
        borderBottom: '1px solid rgba(153, 153, 153, 0.32)',
        cursor: 'pointer'
    },
    activeinputAssessor_name: {
        padding: 10,
        borderBottom: '1px solid rgba(153, 153, 153, 0.32)',
        cursor: 'pointer'
    },
    inputAssessor_name: {
        border: 'solid',
        borderWidth: 1,
        borderColor: '#999',
        borderTopWidth: 0,
        listStyle: 'none',
        marginTop: 0,
        maxHeight: 150,
        overflowY: 'auto',
        paddingLeft: 0,
        backgroundColor: "#fff"
    },
    clearFilterIcon: {
        padding: 5,
        color: 'rgb(138, 141, 150)'
    },
}

export default class Individual_learner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            assessor_data: [],
            isLoader: true,
            AO_list: [],
            learner_data: [],
            learner_id: '',
            learner_progress: '',
            course_data: [],
            selected_course: '',
            AO: '',
            date_registered: '',
            unit_progress_data: [],
        }
        if (this.props.location.state.learner_id) {
            this.state.learner_id = this.props.location.state.learner_id
        }

        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    learnerProgress = () => {
        let learner = JSON.stringify({
            learner_id: this.state.learner_id
        })

        fetch(API + 'get_learner_progress1.php', {
            method: 'POST',
            body: learner
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            this.setState({ isLoader: false })
            if (responseJsonFromServer.result == "success") {
                this.setState({
                    learner_progress: responseJsonFromServer,
                    course_data: responseJsonFromServer.course_data,
                })

                if (responseJsonFromServer.course_data.length !== 0) {
                    this.setState({
                        selected_course: responseJsonFromServer.course_data[0],
                        AO: responseJsonFromServer.course_data[0].ao_id,
                        unit_progress_data: responseJsonFromServer.course_data[0].unit_progress
                    })
                    if (responseJsonFromServer.course_data[0].date_registered != '') {
                        this.setState({ date_registered: moment(responseJsonFromServer.course_data[0].date_registered).toDate() })
                    } else {
                        this.setState({ date_registered: '' })
                    }
                }
            }
        }).catch((error) => {
            if (error.message == 'Failed to fetch') {
                this.setState({ networkError: true })
            }
        });
    }

    componentDidMount() {
        this.learnerProgress();

        fetch(API + 'get_AO.php')
            .then(res => res.json())
            .then((data) => {

                if (data.result === 'success') {
                    this.setState({
                        AO_list: data.ao_data
                    })
                }

            }).catch((error) => {
                console.log(error.message);
            });
    }

    handleSearch = (e) => {
        this.setState({ search: e.target.value })

        let assessor_data = JSON.stringify({
            text: e.target.value
        })

        fetch(API + 'get_all_learner.php', {
            method: 'POST',
            body: assessor_data
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {

                this.setState({
                    learner_data: responseJsonFromServer.search_data
                })
            } else {
                this.setState({
                    learner_data: [],
                    user_message: responseJsonFromServer.msg
                })
            }
        })

        if (e.target.value.trim().length > 0) {
            this.setState({ showLearnerList: true })
        } else {
            this.setState({ showLearnerList: false })
        }
    }

    selectedLearner = (item) => {
        this.setState({ learner_id: item.id, showLearnerList: false, search: item.email }, () => this.learnerProgress())
    }

    handleChangeAO = async (e) => {

        this.setState({ AO: e.target.value })
        let value = e.target.value;

        let users = this.state.course_data;
        users.map((item, i) => {
            if (item.course_id == this.state.selected_course.course_id) {
                return item.ao_id = value;
            }
        })
        await this.setState({ course_data: users });
    }

    handlechangeDateRegistered = async (date) => {
        let value = date;

        this.setState({ date_registered: value })

        let users = this.state.course_data;
        users.map((item, i) => {
            if (item.course_id == this.state.selected_course.course_id) {
                return item.date_registered = value.toLocaleDateString("en-US");
            }
        })
        await this.setState({ course_data: users });

    }

    handleSearchCourse = (e) => {
        let filter_course = []
        filter_course = this.state.course_data.filter(item => {
            return item.course_id == e.target.value
        })
        this.setState({ selected_course: filter_course[0], AO: filter_course[0].ao_id, unit_progress_data: filter_course[0].unit_progress })
        if (filter_course[0].date_registered != '') {
            this.setState({ date_registered: moment(filter_course[0].date_registered).toDate() })
        } else {
            this.setState({ date_registered: '' })
        }
    }

    handleSubmit = () => {

        let progress_data = []
        this.state.course_data.map((item, i) => {
            // return progress_data.push({ course_id: item.course_id, ao_id: item.ao_id, date_registered: item.date_registered.toLocaleDateString("en-US") })
            return progress_data.push({ course_id: item.course_id, ao_id: item.ao_id, date_registered: item.date_registered })
        })

        let data = JSON.stringify({
            learner_id: this.state.learner_progress.id,
            progress_data: progress_data,
            notes: ''
        })

        fetch(API + 'submit_learner_progress.php', {
            method: 'POST',
            body: data
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            var x = document.getElementById("snackbar");
            x.className = "show";
            setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

            if (responseJsonFromServer.result == "success") {

                x.innerHTML = responseJsonFromServer.msg;
                setTimeout(() => {
                    this.learnerProgress();
                }, 1000);

            } else {
                x.innerHTML = responseJsonFromServer.msg;
            }
        })

    }

    hamdleMessage = () => {
        this.props.history.push('/messages')
    }

    handleClearSearch = () => {
        this.setState({ search: '', showLearnerList: false })
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            if (this.state.isLoader) {
                return (
                    <div style={{ minHeight: '100vh', backgroundColor: '#EEF2F6' }}>
                        <Router />
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 80 }}>
                            <img src={require('../../assets/Loader.gif')} style={{ width: 100, height: 100 }} />
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <Router />
                        <div style={{ backgroundColor: '#EEF2F6', minHeight: '100vh', overflowY: 'auto', overflowX: 'hidden' }}>
                            <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingLeft: 20, paddingTop: 80, paddingRight: 20, paddingBottom: 20 }}>
                                <div>
                                    <div className="row" style={{ marginTop: -10, marginBottom: -10, display: 'flex', justifyContent: 'center' }}>
                                        <div className="col-lg-10">
                                            <div className="row">
                                                {this.state.course_data.length !== 0 ? <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h6>Course</h6>
                                                    <select className="form-control" value={this.state.search_course} onChange={this.handleSearchCourse}>
                                                        {this.state.course_data.map((item) => (
                                                            <option key={item.course_id} value={item.course_id}>{item.course_name}</option>
                                                        ))}
                                                    </select>
                                                </div> : <div className="col-lg-6 col-md-6 col-sm-12"></div>}
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h6>Search</h6>
                                                    <div className="col-12 row p-0 m-0">
                                                        <div className="col-11 p-0" style={styles.inputDiv}>
                                                            <input type="text" autoComplete="off" autoCorrect="off" placeholder="Search by name, email and contact number" value={this.state.search} onChange={this.handleSearch} style={styles.inputField} className="form-control" />
                                                            <div style={{ display: this.state.showLearnerList ? '' : 'none' }}>
                                                                {this.state.learner_data.length == 0 ? <div style={{ color: 'rgb(123, 123, 123)', padding: '0.5rem' }}>
                                                                    <em>{this.state.user_message}</em>
                                                                </div> : <ul style={styles.inputAssessor_name}>
                                                                        {this.state.learner_data.map((item, index) =>
                                                                            <li
                                                                                style={index == this.state.activeAssessor ? styles.activeinputAssessor_name : styles.inputAssessor_nameList}
                                                                                onClick={() => this.selectedLearner(item)}
                                                                                id={item.accessor_id}
                                                                            >
                                                                                {item.first_name + ' ' + item.middle_name + ' ' + item.last_name} <br />
                                                                                {item.email} <br />
                                                                                {item.contact}
                                                                            </li>
                                                                        )}
                                                                    </ul>}
                                                            </div>
                                                        </div>
                                                        <div className="col-1 p-0 text-center" style={{ border: '1px solid rgb(206, 212, 218)', borderRadius: 3, cursor: 'pointer', height: 40 }} onClick={this.handleClearSearch}>
                                                            <Icon icon={cross} size="20" style={styles.clearFilterIcon} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="col-lg-10 col-md-10" style={{ paddingTop: 30, display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                        <div className="dropdown-filter"></div>
                                        <div className="dropdown-filter">

                                        </div>
                                        <div className="dropdown-filter">
                                            <Button block style={{ fontWeight: '600' }} onClick={this.hamdleMessage}>Message IQA</Button>
                                        </div>
                                        <div className="dropdown-filter">
                                            <Button block style={{ fontWeight: '600' }} onClick={this.hamdleMessage}>Message learner</Button>
                                        </div>
                                        <div className="dropdown-filter">
                                            <Button block style={{ fontWeight: '600' }} onClick={this.hamdleMessage}>Message Assessor</Button>
                                        </div>
                                        <div className="dropdown-filter">
                                            <Button disabled={this.state.course_data.length == 0} block style={{ fontWeight: '600' }} onClick={this.handleSubmit}>Save</Button>
                                        </div>


                                    </div>
                                    <div className="row p-3">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Learner Name</th>
                                                    <th>Email</th>
                                                    <th width="200">Contact number</th>
                                                    <th>Region</th>
                                                    <th>Course type</th>
                                                    <th>AO</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Date of registered</th>
                                                    <th>Type</th>
                                                    <th>Payment</th>
                                                    <th>Assessor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{this.state.learner_progress.candidate_no}</td>
                                                    <td>{this.state.learner_progress.first_name + ' ' + this.state.learner_progress.middle_name + ' ' + this.state.learner_progress.last_name}</td>
                                                    <td>{this.state.learner_progress.email}</td>
                                                    <td>{this.state.learner_progress.contact}</td>
                                                    <td>{this.state.learner_progress.regions}</td>
                                                    {this.state.course_data.length !== 0 ? <td>{this.state.selected_course.course_name}</td> : <td className="text-center">-</td>}
                                                    <td className="text-center">{this.state.course_data.length !== 0 ? <select style={{ width: 100 }} className="form-control" value={this.state.AO} onChange={this.handleChangeAO}>
                                                        <option value='' hidden>Select</option>
                                                        {this.state.AO_list.map((item) =>
                                                            <option key={item.ao_id} value={item.ao_id}>{item.ao_list}</option>
                                                        )}
                                                    </select> : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                        {this.state.course_data.length !== 0 ?
                                                            <DatePicker

                                                                className="form-control"
                                                                placeholderText="Date of registration"
                                                                showTimeSelect={false}
                                                                dateFormat="dd/MM/yyyy"
                                                                autoComplete="off"
                                                                autoCorrect="off"
                                                                locale={false}
                                                                showTimeInput={false}
                                                                selected={this.state.date_registered}
                                                                onChange={this.handlechangeDateRegistered}
                                                            /> : '-'}
                                                    </td>
                                                    <td>{this.state.learner_progress.learner_type}</td>
                                                    <td>{this.state.learner_progress.payment_plan}</td>
                                                    <td>{this.state.learner_progress.assessor_name}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th width={180}>Unit progress</th>
                                                    {this.state.course_data.length !== 0 ? this.state.unit_progress_data.map((item, i) => (
                                                        <th key={i} width={500}>
                                                            <CircularProgressbar value={item.lesson_progress} text={`${item.lesson_progress}%`} />
                                                        </th>
                                                    )) : <th><h5 className="text-center">No data found</h5></th>}
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <div style={{ width: 200, overflow: 'hidden' }}>
                                                            <span>Assessment result</span><br />
                                                            <span>Date passed</span><br />
                                                            <span>Attempts</span>
                                                        </div>
                                                    </th>
                                                    {this.state.course_data.length !== 0 ? this.state.unit_progress_data.map((item, i) => (
                                                        <th key={i}>
                                                            <div style={{ width: 200, overflow: 'hidden' }}>
                                                                <span style={{ fontWeight: '500' }} style={{ fontWeight: '500' }}>{item.indi_unit_progress}</span><br />
                                                                <span style={{ fontWeight: '500' }}>{item.assessor_check_date == '' || item.assessor_check_date == null ? '-' : item.assessor_check_date}</span><br />
                                                                <span style={{ fontWeight: '500' }}>{item.no_of_attempts ? item.no_of_attempts : '-'}</span>
                                                            </div>
                                                        </th>
                                                    )) : <th><h5 className="text-center">No data found</h5></th>}

                                                </tr>
                                                <tr>
                                                    <th>
                                                        <div style={{ width: 200, overflow: 'hidden' }}>
                                                            <span>IQA name</span><br />
                                                            <span>IQA result</span><br />
                                                            <span>Date passed</span><br />
                                                            <span>Attempts</span>
                                                        </div>
                                                    </th>
                                                    {this.state.course_data.length !== 0 ? this.state.unit_progress_data.map((item, i) => (
                                                        item.iqa_id == null ?
                                                            <th key={i}>
                                                                <div style={{ width: 200, overflow: 'hidden' }}>
                                                                    <span style={{ fontWeight: '500' }}>-</span><br />
                                                                    <span style={{ fontWeight: '500' }}>-</span><br />
                                                                    <span style={{ fontWeight: '500' }}>-</span><br />
                                                                    <span style={{ fontWeight: '500' }}>-</span>
                                                                </div>
                                                            </th> :
                                                            <th>
                                                                <div style={{ width: 200, overflow: 'hidden' }}>
                                                                    <span style={{ fontWeight: '500' }}>{item.iqa_name == '' || item.iqa_name == null ? '-' : item.iqa_name}</span><br />
                                                                    <span style={{ fontWeight: '500' }}>{item.indi_unit_progress == 'Pass' || item.indi_unit_progress == 'Fail' ? item.indi_unit_progress : item.indi_unit_progress.replace('%', '') < 80 ? 'Refer' : 'Pass'}</span><br />
                                                                    <span style={{ fontWeight: '500' }}>{item.iqa_check_date == '' || item.iqa_check_date == null ? '-' : item.iqa_check_date}</span><br />
                                                                    <span style={{ fontWeight: '500' }}>{item.no_of_attempts ? item.no_of_attempts : '-'}</span>
                                                                </div>
                                                            </th>
                                                    )) : <th><h5 className="text-center">No data found</h5></th>}

                                                </tr>
                                            </thead>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="snackbar"></div>
                    </div>
                )
            }
        }
    }
}