import React from 'react';
import API from '../Components/Global_API'
import { Icon } from 'react-icons-kit'
import { trashO, plus } from 'react-icons-kit/fa'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import { Accordion, Card, Button, Media } from 'react-bootstrap'
import NoInternet from '../Components/NoInternet';
var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    continue_button: {
        borderWidth: 2,
        borderColor: 'rgba(0,0,0,0.2)',
        padding: 10,
        backgroundColor: '#fff',
        color: '#000000'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    }
}


export default class TrueFalseFunctionality extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            question_type: this.props.data.question_type,
            title: this.props.data.title,
            question_id: this.props.data.question_id,
            done_text: this.props.data.done_text,
            prompt: this.props.data.prompt,
            lesson_id: this.props.data.lesson_id,
            description: this.props.data.description,
            todos: [{ taskID: 1, task: this.props.data.text }],
            completedTasks: [],
            statements: this.props.data.text,
            correct_answer: this.props.data.correct_answer,
            answerText: this.props.data.answer_text,
            correctAns: this.props.data.correct_reinforcement,
            incorrectAns: this.props.data.incorrect_reinforcement,
            course_image: this.props.course_image,
            done_button_state: true,
            done_button_name: this.props.data.prompt,
            answer_popup: true,
            selected_answer: '',
            answer_reinforcement: '',
            prev_review: true,
            Instruction_Slide: false,
            current_index: '',
            draggedTask: {}
        }
    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    handleAnswerpopup = () => {
        this.setState({ answer_popup: false })
        if (this.state.selected_answer == this.state.correct_answer) {
            this.setState({ answer_reinforcement: this.state.correctAns })
        } else {
            this.setState({ answer_reinforcement: this.state.incorrectAns })
        }
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("title").focus();
        } else if (this.state.statements.trim().length < 2 || this.state.statements.trim().length > 500) {
            document.getElementById("statement").focus();
        } else if (this.state.correctAns.trim().length < 2 || this.state.correctAns.trim().length > 50) {
            document.getElementById("correct_reinforcement").focus();
        } else if (this.state.incorrectAns.trim().length < 2 || this.state.incorrectAns.trim().length > 50) {
            document.getElementById("incorrect_reinforcement").focus();
        } else if (this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 50) {
            document.getElementById("prompt").focus();
        } else if (this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 30) {
            document.getElementById("done_text").focus();
        } else {
            let True_False = JSON.stringify(
                {
                    title: this.state.title,
                    description: this.state.description,
                    lesson_id: this.state.lesson_id,
                    true_false_id: this.state.question_id,
                    answer_text: this.state.answerText,
                    correct_reinforcement: this.state.correctAns,
                    incorrect_reinforcement: this.state.incorrectAns,
                    text: this.state.statements,
                    correct_answer: this.state.correct_answer,
                    prompt: this.state.prompt,
                    done_text: this.state.done_text,
                    dropDone: false,
                });
            fetch(API + 'edit_true_false.php',
                {
                    method: 'POST',

                    body: True_False
                }).then((response) => response.json()).then((responseJsonFromServer) => {

                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);
                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }
                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });

        }
    }

    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_title')[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 2 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    statementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_statement')[0].innerHTML = "Text is required.";
            document.getElementsByClassName('error_statement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 500) {
            document.getElementsByClassName('error_statement')[0].innerHTML = 'Text must be between 2 to 500 character.';
            document.getElementsByClassName('error_statement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_statement')[0].innerHTML = "";
            document.getElementsByClassName('error_statement')[0].style.display = 'none';
        }
    }

    answer_textValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length < 0 || value.trim().length > 150) {
            document.getElementsByClassName('error_answer_text')[0].innerHTML = 'Answer text must be between 0 to 150 character.';
            document.getElementsByClassName('error_answer_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_answer_text')[0].innerHTML = "";
            document.getElementsByClassName('error_answer_text')[0].style.display = 'none';
        }
    }

    correct_reinforcementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = "Correct reinforcement is required.";
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = 'Correct reinforcement must be between 2 to 50 character.';
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = "";
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'none';
        }
    }

    incorrect_reinforcementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = "Incorrect reinforcement is required.";
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = 'Incorrect reinforcement must be between 2 to 50 character.';
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = "";
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    done_textValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }


    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    handleChangeDoneText = (e) => {
        this.setState({ done_text: e.target.value });
    }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleChangeDescription = (e) => {
        this.setState({ description: e.target.value });
    }

    handleChangeAnswerText = (e) => {
        this.setState({ answerText: e.target.value });
    }

    handleChangeCorrectAns = (e) => {
        this.setState({ correctAns: e.target.value });
    }

    handleChangeIncorrectAns = (e) => {
        this.setState({ incorrectAns: e.target.value });
    }

    onMouseUp = (e) => {
        var elmnt = document.getElementById('dragabble_div');
        elmnt.style.display = 'none';
        if (this.state.dropDone) {
            elmnt.style.display = 'none';
        } else {

            var div_true = document.getElementById('div_true');
            var div_false = document.getElementById('div_false');
            div_true.style.backgroundColor = "rgba(255, 255, 255, 0.15)";
            div_false.style.backgroundColor = "rgba(255, 255, 255, 0.15)";



            elmnt.style.display = 'block';
        }
    }
    onDrag = (event, todo) => {
        var elmnt = document.getElementById('dragabble_div');
        elmnt.style.display = 'none';
        event.preventDefault();
        this.setState({
            draggedTask: todo
        });
    }
    onDragOverTrue = (event) => {
        this.setState({ selected_answer: 'true' })
        var div_true = document.getElementById('div_true');
        var div_false = document.getElementById('div_false');
        div_true.style.backgroundColor = "rgba(212, 249, 10, 0.61)";
        div_false.style.backgroundColor = "rgba(255, 255, 255, 0.15)";
        event.preventDefault();
    }

    onDragOverFalse = (event) => {
        this.setState({ selected_answer: 'false' })
        event.preventDefault();
        var div_false = document.getElementById('div_false');
        var div_true = document.getElementById('div_true');
        div_true.style.backgroundColor = "rgba(255, 255, 255, 0.15)";
        div_false.style.backgroundColor = "rgba(212, 249, 10, 0.61)";
    }

    onDropFalse = (event) => {
        const { completedTasks, draggedTask, todos } = this.state;
        this.setState({
            //   completedTasks: [...completedTasks, draggedTask],
            todos: todos.filter(task => task.taskID !== draggedTask.taskID),
            done_button_state: false,
            done_button_name: this.state.done_text,
            dropDone: true
        });


    }
    onDropTrue = (event) => {
        const { completedTasks, draggedTask, todos } = this.state;
        this.setState({
            //   completedTasks: [...completedTasks, draggedTask],
            todos: todos.filter(task => task.taskID !== draggedTask.taskID),
            done_button_state: false,
            done_button_name: this.state.done_text,
            dropDone: true
        });
    }

    handleChangeCorrect = (e) => {
        let value = JSON.stringify(e.target.checked)

        this.setState({ correct_answer: value });

    }

    handleChangeText = (e) => {
        this.setState({ statements: e.target.value });
    }


    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            const { todos, completedTasks } = this.state;
            if (!this.state.isFetching) {
                return (

                    <div className="row text-center">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="card card-sm">
                                <div className="card-content block-xl"  >
                                    <div className="body-container" style={styles.body}>
                                        <div className="container-fluid" style={{ height: 550, padding: 0, backgroundColor: 'rbga(0,0,0,0.7)', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${this.state.course_image})` }} >

                                            <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.Instruction_Slide ? "hidden" : "visible", position: 'absolute', padding: 0 }}>
                                                <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 21, paddingLeft: 15, paddingRight: 15 }}>
                                                    <div className="row text-center">
                                                        <div style={{ width: '10%', paddingLeft: 5 }}>
                                                            <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                                        </div>
                                                        <div style={{ paddingTop: 15, width: '60%' }}>
                                                            {/* <text style={{color: '#fff',fontSize:20}}>Scrollable</text> */}
                                                            <hr color='#fff' />
                                                        </div>
                                                        <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                            <div style={styles.dot_btn}>
                                                                <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                                <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ height: 380, paddingTop: 35, display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
                                                        <h5 style={{ color: '#fff' }}>{this.state.title}</h5>
                                                        <p style={{ color: '#fff' }}>{this.state.description}</p>
                                                        {/* <Button variant="light" style={{color: '#000000'}}>OK I'm ready!</Button> */}
                                                        <div className="text-center">
                                                            <button className="btn" onClick={() => this.setState({ Instruction_Slide: true })} style={{ backgroundColor: '#fff', marginBottom: 30, color: '#000000', borderWidth: 1, borderColor: '#fff', fontWeight: 'bold' }}> OK I'm ready!</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 21, paddingLeft: 15, paddingRight: 15 }}>
                                                <div className="row text-center">
                                                    <div style={{ width: '10%', paddingLeft: 5 }}>
                                                        <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                                    </div>
                                                    <div style={{ paddingTop: 15, width: '60%' }}>
                                                        {/* <text style={{color: '#fff',fontSize:20}}>Scrollable</text> */}
                                                        <hr color='#fff' />
                                                    </div>
                                                    <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                        <div style={styles.dot_btn}>
                                                            <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                            <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ height: 380, fontSize: 15, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <div className="row text-center" style={{ position: 'relative' }} >
                                                        <div id="div_true" className="col-2" style={{ backgroundColor: 'rgba(255, 255, 255, 0.15)', marginTop: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <span style={{ color: '#fff', position: 'absolute' }}>True</span>
                                                            <div
                                                                className="done"
                                                                onDrop={event => this.onDropTrue(event)}
                                                                onDragOver={(event => this.onDragOverTrue(event))}
                                                            >
                                                                {completedTasks.map((task, index) =>
                                                                    <div key={task.taskID}>
                                                                        {task.task}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-8" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                                <div className="todos" >
                                                                    {
                                                                        todos.map(todo =>
                                                                            <div
                                                                                id="dragabble_div"
                                                                                key={todo.taskID}
                                                                                draggable
                                                                                onDragEnd={(event => this.onMouseUp(event))}
                                                                                onDrag={(event) => this.onDrag(event, todo)}
                                                                                style={{ backgroundColor: '#fff', color: 'black', padding: 15, overflowY: 'auto', maxHeight: 200 }}
                                                                            >
                                                                                {todo.task}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div id="div_false" className="col-2" style={{ backgroundColor: 'rgba(255,255, 255, 0.15)', marginTop: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <span style={{ color: '#fff', position: 'absolute' }}>False</span>
                                                            <div
                                                                className="done"
                                                                onDrop={event => this.onDropFalse(event)}
                                                                onDragOver={(event => this.onDragOverFalse(event))}
                                                            >
                                                                {completedTasks.map((task, index) =>
                                                                    <div key={task.taskID}>
                                                                        {task.task}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ position: 'relative' }} >
                                                    <hr color='#fff' />
                                                    <div className="text-center">
                                                        <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleAnswerpopup() }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                                                            {this.state.done_button_state ? this.state.prompt : this.state.done_text}
                                                        </button>
                                                    </div>
                                                    <div style={{ backgroundColor: '#fff', visibility: this.state.answer_popup ? "hidden" : "visible", marginTop: -200, position: 'relative', marginLeft: -15, marginRight: -15, padding: 15, borderTop: 'solid', borderTopColor: 'rgba(0,0,0,0.4)', borderTopWidth: 5 }}>
                                                        <div style={{ overflowY: 'auto', height: 90 }}>
                                                            <p className="text-left"><strong>{this.state.answer_reinforcement}</strong></p>
                                                            <div className="text-left">
                                                                <p>{this.state.answerText}</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                            <div className="col-6">
                                                                <Button style={styles.continue_button} onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} block>Continue</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                                <div>
                                                    <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                        <div className="text-center">
                                                            <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                                Review last slide
                                                </button>
                                                        </div>
                                                        <div className="text-center">
                                                            <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                                Restart lesson
                                                </button>
                                                        </div>
                                                        {/* <div className="text-center">
                                                <button className="btn" style={styles.previewbutton}>
                                                    Exit lesson
                                                </button>
                                            </div> */}
                                                        <div className="text-center">
                                                            <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                                Cancel
                                                </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                                    <div className="col-4">
                                        <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                                    </div>
                                    <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                        <span >Slide {this.props.current_index + 1}</span>
                                    </div>
                                    <div className="col-4">
                                        <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="card-sm" >
                                <form>
                                    <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', overflowX: 'hidden' }}>
                                        <h5 className="text-left" style={{ paddingBottom: 10 }}>True False</h5>

                                        <div className="text-left">
                                            <div className="form-group">
                                                <div className="input-group-prepend" style={styles.textbox}>
                                                    <span className="input-group-text" style={styles.text}>Title*</span>
                                                </div>
                                                <input type="text" className="form-control" id="title" minLength={2} maxLength={250} value={this.state.title} onChange={this.handleChangeTitle} onBlur={this.titleValidation} />
                                                <label className="input-error error_title"></label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group-prepend" style={styles.textbox}>
                                                    <span className="input-group-text" style={styles.text} >Description</span>
                                                </div>
                                                <textarea value={this.state.description} id="description" minLength={2} maxLength={250} onChange={this.handleChangeDescription} rows="3" className="form-control"  ></textarea>
                                            </div>

                                            <div className="form-group">
                                                {/* <Accordion style={{ textAlign: 'left' }} defaultActiveKey="0"> */}
                                                <div style={{ backgroundColor: '#EEF2F6', marginTop: -5 }}>
                                                    <div style={{ height: 40 }} eventkey="0">
                                                        <h5>Statements</h5>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group-prepend" style={styles.textbox}>
                                                            <span className="input-group-text" style={styles.text} >Text*</span>
                                                        </div>
                                                        <input type="text" className="form-control" id="statement" minLength={2} maxLength={500} value={this.state.statements} onChange={this.handleChangeText.bind(this)} onBlur={this.statementValidation} />
                                                        <label className="input-error error_statement"></label>
                                                    </div>
                                                    <div className="form-group">
                                                        <label><input type="checkbox" style={{ height: 15, width: 15 }} checked={this.state.correct_answer == 'true' ? true : false} onChange={this.handleChangeCorrect.bind(this)} /><span style={{ paddingLeft: 10 }}>Correct</span></label>
                                                    </div>
                                                    <div >
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <Accordion style={{ textAlign: 'left' }} defaultActiveKey="0">
                                                    <div>
                                                        <div as={Card.Body} eventkey="0">
                                                            <h5>Answer</h5>
                                                        </div>
                                                        <Accordion.Collapse eventKey="0">
                                                            <div>
                                                                <div className="form-group">
                                                                    <div className="input-group-prepend" style={styles.textbox}>
                                                                        <span className="input-group-text" style={styles.text}>Answer Text</span>
                                                                    </div>
                                                                    <textarea type="text" className="form-control" maxLength={150} rows={3} id="answer_text" value={this.state.answerText} onChange={this.handleChangeAnswerText} onBlur={this.answer_textValidation} />
                                                                    <label className="input-error error_answer_text"></label>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="input-group-prepend" style={styles.textbox}>
                                                                        <span className="input-group-text" style={styles.text}>Correct Reinforcement*</span>
                                                                    </div>
                                                                    <input type="text" className="form-control" id="correct_reinforcement" minLength={2} maxLength={50} value={this.state.correctAns} onChange={this.handleChangeCorrectAns} onBlur={this.correct_reinforcementValidation} />
                                                                    <label className="input-error error_correct_reinforcement"></label>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="input-group-prepend" style={styles.textbox}>
                                                                        <span className="input-group-text" style={styles.text}>Incorrect Reinforcement*</span>
                                                                    </div>
                                                                    <input type="text" className="form-control" id="incorrect_reinforcement" value={this.state.incorrectAns} minLength={2} maxLength={50} onChange={this.handleChangeIncorrectAns} onBlur={this.incorrect_reinforcementValidation} />
                                                                    <label className="input-error error_incorrect_reinforcement"></label>
                                                                </div>
                                                            </div>
                                                        </Accordion.Collapse>
                                                    </div>
                                                </Accordion>
                                            </div>

                                            <div className="form-group">
                                                <div className="input-group-prepend" style={styles.textbox}>
                                                    <span className="input-group-text" style={styles.text} >Prompt*</span>
                                                </div>
                                                <input type="text" value={this.state.prompt} id="prompt" minLength={2} maxLength={50} onChange={this.handleChangePrompt} className="form-control" onBlur={this.promptValidation} />
                                                <label className="input-error error_prompt"></label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group-prepend" style={styles.textbox}>
                                                    <span className="input-group-text" style={styles.text}>Done text*</span>
                                                </div>
                                                <input type="text" value={this.state.done_text} id="done_text" minLength={2} maxLength={30} onChange={this.handleChangeDoneText} className="form-control" onBlur={this.done_textValidation} />
                                                <label className="input-error error_done_text"></label>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        <button onClick={this.handleSubmit} className="btn btn-dark" style={{ width: 150 }}>Save</button>
                                        <div id="snackbar"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <p>{this.state.isFetching ? 'Fetching question data...' : ''}</p>
                )
            }
        }
    }
}