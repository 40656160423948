import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap'
import API from '../Components/Global_API'
import { Icon } from 'react-icons-kit'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import NoInternet from '../Components/NoInternet';

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    upload_button_label: {
        backgroundColor: '#fff',
        marginBottom: 5,
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    },
    slide: {
        width: '40%',
        height: 96,
        float: 'left',
        textAlign: 'center',
        margin: 4,
        background: '#f5f7fa',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'inherit',
        position: 'relative',
        borderRadius: 3
    },
    radio: {
        borderWidth: 1,
        backgroundColor: '#fff',
        margin: 5,
        paddingLeft: 20,
        borderRadius: 5,
        fontSize: 15,
        paddingTop: 5
    },
}

export default class QuestionPool_assessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.data.pool_questions_data[this.props.question_pool_current_index].pool_question_title,
            done_text: this.props.data.pool_questions_data[this.props.question_pool_current_index].done_text,
            prompt: this.props.data.pool_questions_data[this.props.question_pool_current_index].prompt,
            done_button_state: true,
            current_index: this.props.current_index,
            prev_review: true,
            question_data: this.props.data.pool_questions_data,
            question_pool: '',
            multiple_data: false,
            question_pool_current_index: this.props.question_pool_current_index
        }

        let data = [];

        this.state.question_data.map(item => {
            return data.push(item)
        })

        function shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        }
        data = shuffle(data)
        this.state.question_pool = data[0]
    }

    handleQuestionPoolData = () => {
        this.props.handleQuestionPoolData(this.state.question_data)
    }

    DisableButtonFunction = (e) => {
        this.setState({ done_button_state: false })
    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    handleSubmit = () => {
        if (this.state.prompt.trim().length < 2) {
            document.getElementById("prompt").focus();
        } else {
            let free_text = JSON.stringify({
                unit_id: this.state.unit.unit_id,
                lesson_id: '0',
                title: this.state.title,
                prompt: this.state.prompt,
                done_text: this.state.done_text,
                free_text_id: this.state.question_id
            })

            fetch(API + 'edit_free_text.php',
                {
                    method: 'POST',

                    body: free_text
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        this.props.handleUpdateQuetionData();

                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }

    handleContentValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_content')[0].innerHTML = "Content is required.";
            document.getElementsByClassName('error_content')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 100) {
            document.getElementsByClassName('error_content')[0].innerHTML = 'Content must be between 2 to 100 character.';
            document.getElementsByClassName('error_content')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_content')[0].innerHTML = "";
            document.getElementsByClassName('error_content')[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    DoneTextValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }

    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleChangeDone_text = (e) => {
        this.setState({ done_text: e.target.value });
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handleFreeText = (e) => {
        this.setState({ free_text: e.target.value })
    }

    handleKeyDown = (e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            return (
                <div>
                    <div className="row text-center">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="card card-sm">
                                <div className="card-content block-xl"  >
                                    <div className="body-container" style={styles.body}>
                                        <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }}>
                                            <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                                <div className="row text-center">
                                                    <div style={{ width: '10%', paddingLeft: 5 }}>
                                                        <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                                    </div>
                                                    <div style={{ paddingTop: 15, width: '60%' }}>
                                                        <hr color="#fff" />
                                                    </div>
                                                    <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                        <div style={styles.dot_btn}>
                                                            <label style={{ color: '#fff', fontSize: 15, padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                            <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ height: 380, paddingTop: 35, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <div>
                                                        <h5 style={{ color: '#fff', paddingBottom: 10 }}> {this.state.question_pool.pool_question_title}</h5>
                                                        {this.state.question_pool.pool_multiple_choice_options.map((item, i) => (
                                                            <div style={styles.radio} className="text-left" key={i}>
                                                                <label style={{ width: '100%' }}>
                                                                    <input type="radio" name="option" value={item.options} onClick={this.DisableButtonFunction} />&nbsp;{item.options}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div>
                                                    <hr color='#fff' />
                                                    <div className="text-center">
                                                        <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                                                            {this.state.done_button_state ? this.state.question_pool.prompt : this.state.question_pool.done_text}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, width: 363, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                                <div>
                                                    <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                        <div className="text-center">
                                                            <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                                Review last slide
                                                    </button>
                                                        </div>
                                                        <div className="text-center">
                                                            <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                                Restart lesson
                                                            </button>
                                                        </div>
                                                        <div className="text-center">
                                                            <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                                    <div className="col-4">
                                        <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                                    </div>
                                    <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                        <span >Slide {this.props.current_index + 1}</span>
                                    </div>
                                    <div className="col-4">
                                        <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="card-sm" >
                                <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', overflowX: 'hidden' }}>
                                    <h5 className="text-left" style={{ paddingBottom: 10 }}>Question Pool</h5>
                                    <div className="text-left">
                                        <div className="form-group">
                                            <div className="input-group-prepend" style={styles.textbox}>
                                                <span className="input-group-text" style={styles.text}>Question slides</span>
                                            </div>
                                            <div style={{ backgroundColor: '#e4eaf1', border: '1px solid #cdd9e4', padding: 10 }}>
                                                <button className="btn btn-default btn-sm" style={{ color: '#1f2e3c', backgroundColor: '#e6ebf0', border: '1px solid #d7dfe7', fontSize: 15, fontWeight: '600' }} onClick={() => { this.handleQuestionPoolData(); this.setState({ multiple_data: true }) }}>Edit Slides</button> Add the slides that will be shown in this question pool.
                                                <ul style={{ overflow: 'hidden', margin: -4, paddingLeft: 0, listStyle: 'none', paddingTop: 10, borderRadius: 3 }}>
                                                    {this.state.question_data.map((item, i) =>
                                                        <li style={styles.slide} key={i}>
                                                            <div style={{ display: 'flex', position: 'relative', borderRadius: 3, backgroundColor: 'rgb(29, 46, 67)', userSelect: 'none', padding: '16px', outline: 'none', color: '#fff', width: '95%' }}>
                                                                <div style={{ display: 'block', padding: '0 24px 0 8px', overflow: 'hidden', textAlign: 'left' }}>
                                                                    <h6>Multiple Choice</h6>
                                                                    <span style={{ color: 'rgb(142, 150, 161)', fontSize: 12, lineHeight: 0, fontWeight: '400', maxHeight: 32, display: 'inline-block', verticalAlign: 'top', margin: '4px 0px 0px' }}>{item.pool_question_title}</span>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}