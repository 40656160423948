import React from 'react'
import Router from '../Router'
import Footer from '../Components/Footer'

const styles={
    flex: {
        height: 100, 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center'
    },
    box: { 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center' 
    }
}

export default class Billing_details extends React.Component {
    render() {
        return (
            <div>
                <Router />
                <div style={{ backgroundColor: '#dfe2e6' }}>
                    <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingTop: 60, height: 370 }}>
                        <div className="row" style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10, paddingBottom: 10 }}>
                            <div className="col-12 block-v-sm">
                                <label style={{ fontSize: 25 }}>Billing Details</label>
                                <hr />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div className="col-12" style={{ position: 'absolute', top: 230 }}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                    <div className="col-3 shadow p-3 mb-5 bg-white" style={styles.box}>
                                        <div style={styles.flex}>
                                            <h3 className="text-center">Active users</h3>
                                        </div>
                                        <div>
                                            <hr />
                                        </div>
                                        <div style={styles.flex}>
                                            <h1 className="text-center">0</h1>
                                        </div>
                                    </div>
                                    <div className="col-3 shadow p-3 mb-5 bg-white" style={styles.box}>
                                        <div style={styles.flex}>
                                            <h3 className="text-center">Authoring and delivery plan</h3>
                                        </div>
                                        <div >
                                            <hr />
                                        </div>
                                        <div style={styles.flex}>
                                            <h1 className="text-center">FREE</h1>
                                        </div>
                                    </div>
                                    <div className="col-3 shadow p-3 mb-5 bg-white" style={styles.box}>
                                        <div style={styles.flex}>
                                            <h3 className="text-center">Pro authoring plan</h3>
                                        </div>
                                        <div >
                                            <hr />
                                        </div>
                                        <div style={styles.flex}>
                                            <h1 className="text-center">FREE</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ height: 250, display:'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div style={{ display:'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingBottom: 40 }}>
                            <button type="button" className="btn btn-dark">Upgrade your plan</button>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}