import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from 'react-icons-kit'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import Slider from '@material-ui/core/Slider';
import { Accordion, Card, Button } from 'react-bootstrap'
import API from '../Components/Global_API'
import NoInternet from '../Components/NoInternet';

const useStyles = makeStyles(theme => ({
    root: {
        width: 300,
    },
    margin: {
        height: theme.spacing(4),
        margin: 0 - 8,

    },
}));

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    continue_button: {
        borderWidth: 2,
        borderColor: 'rgba(0,0,0,0.2)',
        padding: 10,
        backgroundColor: '#fff',
        color: '#000000'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    }
}


export default class SliderFunctionality extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lebels: [],
            minVal: this.props.data.min_value,
            minimum_val_label: this.props.data.min_value,
            maximum_val_label: this.props.data.max_value,
            label_count_value: this.props.data.label,
            increment_value_label: this.props.data.increment,
            maxVal: this.props.data.max_value,
            labelCount: this.props.data.label,
            increment: this.props.data.increment,
            title: this.props.data.title,
            prompt: this.props.data.prompt,
            done_text: this.props.data.done_text,
            slider_id: this.props.data.question_id,
            answer_text: this.props.data.answer_text,
            correct_value: this.props.data.correct_value,
            correct_reinforcement: this.props.data.correct_reinforcement,
            incorrect_reinforcement: this.props.data.incorrect_reinforcement,
            lesson_id: this.props.data.lesson_id,
            slider_id: this.props.data.question_id,
            course_image: this.props.course_image,
            done_button_state: true,
            done_button_name: this.props.data.prompt,
            answer_popup: true,
            selected_answer: '',
            answer_reinforcement: '',
            prev_review: true
        }
    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    handleChange = (event, value) => {
        this.setState({
            done_button_state: false,
            done_button_name: this.state.done_text,
            selected_answer: value
        })

    }
    handleAnswerpopup = () => {
        this.setState({ answer_popup: false })
        if (this.state.selected_answer == this.state.correct_value) {
            this.setState({ answer_reinforcement: this.state.correct_reinforcement })
        } else {
            this.setState({ answer_reinforcement: this.state.incorrect_reinforcement })
        }
    }

    componentDidMount() {

        let minVal = parseInt(this.state.minVal);
        let maxVal = parseInt(this.state.maxVal);
        let labelCount = parseInt(this.state.labelCount);
        let increment = parseInt(this.state.increment);
        this.state.minVal = minVal;
        this.state.maxVal = maxVal;
        this.state.labelCount = labelCount;
        this.state.increment = increment;

        let diff = maxVal - minVal;
        let dispLable = labelCount - 1;
        let incrLabelValue = diff / dispLable;
        let lebelData = [];
        lebelData.push({ value: minVal, label: minVal })
        let newVal = minVal + incrLabelValue;

        for (let i = 0; i < dispLable - 1; i++) {
            // lebelData.push({ value: newVal, label: newVal.toFixed(2) })
            lebelData.push({ value: Math.round(newVal), label: Math.round(newVal) })
            newVal = newVal + incrLabelValue;
        }
        lebelData.push({ value: maxVal, label: maxVal })
        this.setState({ minVal: minVal, maxVal: maxVal, labelCount: labelCount, increment: increment, lebels: lebelData });
    }

    handleSubmit = () => {
        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("title").focus();
        } else if (this.state.minimum_val_label.length < 0 || this.state.minimum_val_label.length > 4 || this.state.minimum_val_label > this.state.maximum_val_label) {
            document.getElementById("min_value").focus();
        } else if (this.state.maximum_val_label.length < 0 || this.state.maximum_val_label.length > 4 || this.state.minimum_val_label > this.state.maximum_val_label) {
            document.getElementById("max_value").focus();
        } else if (this.state.increment_value_label.length < 0 || this.state.increment_value_label.length > 4) {
            document.getElementById("increment").focus();
        } else if (this.state.label_count_value.length < 0 || this.state.label_count_value.length > 4) {
            document.getElementById("label").focus();
        } else if (this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 50) {
            document.getElementById("prompt").focus();
        } else if (this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 30) {
            document.getElementById("done_text").focus();
        } else if (this.state.answer_text.trim().length < 2 || this.state.answer_text.trim().length > 150) {
            document.getElementById("answer_text").focus();
        } else if (this.state.correct_value.trim().length < 0 || this.state.correct_value.trim().length > 4) {
            document.getElementById("correct_answer").focus();
        } else if (this.state.correct_reinforcement.trim().length < 2 || this.state.correct_reinforcement.trim().length > 50) {
            document.getElementById("correct_reinforcement").focus();
        } else if (this.state.incorrect_reinforcement.trim().length < 2 || this.state.incorrect_reinforcement.trim().length > 50) {
            document.getElementById("incorrect_reinforcement").focus();
        } else {
            let Slider = JSON.stringify(
                {
                    lesson_id: this.state.lesson_id,
                    title: this.state.title,
                    min_value: this.state.minimum_val_label,
                    max_value: this.state.maximum_val_label,
                    label: this.state.label_count_value,
                    increment: this.state.increment_value_label,
                    prompt: this.state.prompt,
                    done_text: this.state.done_text,
                    slider_id: this.state.slider_id,
                    answer_text: this.state.answer_text,
                    correct_value: this.state.correct_value,
                    correct_reinforcement: this.state.correct_reinforcement,
                    incorrect_reinforcement: this.state.incorrect_reinforcement
                });

            fetch(API + 'edit_slider.php',
                {
                    method: 'POST',

                    body: Slider
                }).then((response) => response.json()).then((responseJsonFromServer) => {

                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);
                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }

    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_title')[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 2 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    min_valueValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_min_value')[0].innerHTML = "Minimum value is required.";
            document.getElementsByClassName('error_min_value')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 0 || value.trim().length > 4) {
            document.getElementsByClassName('error_min_value')[0].innerHTML = "Minimum value must be between 2 to 4 digit.";
            document.getElementsByClassName('error_min_value')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (this.state.maximum_val_label < value) {
            document.getElementsByClassName('error_min_value')[0].innerHTML = "Minimum value should not be greater than maximum value.";
            document.getElementsByClassName('error_min_value')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_min_value')[0].innerHTML = "";
            document.getElementsByClassName('error_min_value')[0].style.display = 'none';
        }
    }

    max_valueValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_max_value')[0].innerHTML = "Maximum value is required.";
            document.getElementsByClassName('error_max_value')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 0 || value.trim().length > 4) {
            document.getElementsByClassName('error_max_value')[0].innerHTML = "Maximum value must be between 2 to 4 digit.";
            document.getElementsByClassName('error_max_value')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (this.state.minimum_val_label > value) {
            document.getElementsByClassName('error_max_value')[0].innerHTML = "Maximum value should not be less than minimum value.";
            document.getElementsByClassName('error_max_value')[0].style.display = 'block';
            e.target.classList.add('error');
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_max_value')[0].innerHTML = "";
            document.getElementsByClassName('error_max_value')[0].style.display = 'none';
        }
    }

    incrementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_increment')[0].innerHTML = "Increment value is required.";
            document.getElementsByClassName('error_increment')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_increment')[0].innerHTML = "";
            document.getElementsByClassName('error_increment')[0].style.display = 'none';
        }
    }

    labelValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_label')[0].innerHTML = "Label value is required.";
            document.getElementsByClassName('error_label')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_label')[0].innerHTML = "";
            document.getElementsByClassName('error_label')[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    done_textValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }

    answerTextValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length > 150) {
            document.getElementsByClassName('error_answer_text')[0].innerHTML = 'Answer text must be between 0 to 150 character.';
            document.getElementsByClassName('error_answer_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_answer_text')[0].innerHTML = "";
            document.getElementsByClassName('error_answer_text')[0].style.display = 'none';
        }
    }

    correct_answerValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_correct_answer')[0].innerHTML = "Correct answer is required.";
            document.getElementsByClassName('error_correct_answer')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 0 || value.trim().length > 4) {
            document.getElementsByClassName('error_correct_answer')[0].innerHTML = 'Correct answer must be between 1 to 4 character.';
            document.getElementsByClassName('error_correct_answer')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_correct_answer')[0].innerHTML = "";
            document.getElementsByClassName('error_correct_answer')[0].style.display = 'none';
        }
    }

    correct_reinforcementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = "Correct reinforcement is required.";
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = 'Correct reinforcement must be between 2 to 50 character.';
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = "";
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'none';
        }
    }

    incorrect_reinforcementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = "Incorrect reinforcement is required.";
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = 'Incorrect reinforcement must be between 2 to 50 character.';
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = "";
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'none';
        }
    }

    number_press = (event) => {
        if (!(event.ctrlKey || event.altKey
            || (47 < event.keyCode && event.keyCode < 58 && event.shiftKey == false)
            || (95 < event.keyCode && event.keyCode < 106)
            || (event.keyCode == 8) || (event.keyCode == 9)
            || (event.keyCode > 34 && event.keyCode < 40)
            || (event.keyCode == 46))) {

            event.preventDefault();
        }

    }

    handleTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    handleMinValue = (e) => {
        this.setState({ minimum_val_label: e.target.value });
    }

    handleMaxValue = (e) => {
        this.setState({ maximum_val_label: e.target.value });
    }

    handleLabels = (e) => {
        this.setState({ label_count_value: e.target.value });
    }

    handleIncrement = (e) => {
        this.setState({ increment_value_label: e.target.value });
    }

    handlePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleDoneText = (e) => {
        this.setState({ done_text: e.target.value });
    }

    handleAnswer_text = (e) => {
        this.setState({ answer_text: e.target.value });
    }

    handlecCorrect_value = (e) => {
        this.setState({ correct_value: e.target.value });
    }

    handleCorrect_reinforcement = (e) => {
        this.setState({ correct_reinforcement: e.target.value });
    }

    handleIncorrect_reinforcement = (e) => {
        this.setState({ incorrect_reinforcement: e.target.value });
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            let data = this.state.lebels.map(item => console.log(item))
            return (
                <div className="row text-center">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="card card-sm">
                            <div className="card-content block-xl" >
                                <div className="body-container" style={styles.body}>
                                    <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }} >
                                        <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                            <div className="row text-center">
                                                <div style={{ width: '10%', paddingLeft: 5 }}>
                                                    <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                                </div>
                                                <div style={{ paddingTop: 15, width: '60%' }}>
                                                    {/* <text style={{color: '#fff',fontSize:20}}>Scrollable</text> */}
                                                    <hr color='#fff' />
                                                </div>
                                                <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                    <div style={styles.dot_btn}>
                                                        <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                        <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* -------------------------------------------- */}

                                            <div style={{ height: 380, fontSize: 20, paddingLeft: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <div style={{ marginBottom: 50 }}>
                                                    <h4 style={{ color: '#fff' }}>{this.state.title}</h4>
                                                </div>
                                                <div style={useStyles.root} >
                                                    <div style={useStyles.margin} />

                                                    <Slider
                                                        min={this.state.minVal}
                                                        max={this.state.maxVal}
                                                        step={this.state.increment}
                                                        marks={this.state.lebels}
                                                        style={{ color: '#fff' }}
                                                        disabled={!this.state.answer_popup}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>

                                            {/* ---------------------------------------- */}

                                            <div>
                                                <hr color='#fff' />
                                                <div className="text-center">
                                                    <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleAnswerpopup() }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                                                        {this.state.done_button_state ? this.state.prompt : this.state.done_text}
                                                    </button>
                                                </div>
                                                <div style={{ backgroundColor: '#fff', visibility: this.state.answer_popup ? "hidden" : "visible", marginTop: -200, position: 'relative', marginLeft: -15, marginRight: -15, padding: 15, borderTop: 'solid', borderTopColor: 'rgba(0,0,0,0.4)', borderTopWidth: 5 }}>
                                                    <div style={{ overflowY: 'auto', height: 95 }}>
                                                        <p className="text-left"><strong>{this.state.answer_reinforcement}</strong></p>
                                                        <div className="text-left">
                                                            <p>{this.state.answer_text}</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        <div className="col-6">
                                                            <Button style={styles.continue_button} onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} block>Continue</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                            <div>
                                                <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                    <div className="text-center">
                                                        <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                            Review last slide
                                                </button>
                                                    </div>
                                                    <div className="text-center">
                                                        <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                            Restart lesson
                                                </button>
                                                    </div>
                                                    {/* <div className="text-center">
                                                <button className="btn" style={styles.previewbutton}>
                                                    Exit lesson
                                                </button>
                                            </div> */}
                                                    <div className="text-center">
                                                        <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                            Cancel
                                                </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                                <div className="col-4">
                                    <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                                </div>
                                <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                    <span >Slide {this.props.current_index + 1}</span>
                                </div>
                                <div className="col-4">
                                    <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <div className="card-sm" >
                            <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', overflowX: 'hidden' }}>
                                <h4 className="text-left" style={{ paddingBottom: 10 }}>Slider</h4>
                                <div className="text-left">
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text}>Title*</span>
                                        </div>
                                        <input type="text" className="form-control" value={this.state.title} id="title" minLength={2} maxLength={250} onChange={this.handleTitle.bind(this)} onBlur={this.titleValidation} />
                                        <label className="input-error error_title"></label>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text}>Min.Value*</span>
                                        </div>
                                        <input type="text" className="form-control" id="min_value" value={this.state.minimum_val_label} minLength={1} maxLength={4} onChange={this.handleMinValue.bind(this)} onBlur={this.min_valueValidation} onKeyDown={this.number_press} />
                                        <label className="input-error error_min_value"></label>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text} >Max.Value*</span>
                                        </div>
                                        <input type="text" className="form-control" id="max_value" value={this.state.maximum_val_label} minLength={1} maxLength={4} onChange={this.handleMaxValue.bind(this)} onBlur={this.max_valueValidation} onKeyDown={this.number_press} />
                                        <label className="input-error error_max_value"></label>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text}>Increment*</span>
                                        </div>
                                        <input type="text" className="form-control" id="increment" minLength={1} maxLength={4} value={this.state.increment_value_label} onChange={this.handleIncrement.bind(this)} onBlur={this.incrementValidation} onKeyDown={this.number_press} />
                                        <label className="input-error error_increment"></label>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text}>Labels*</span>
                                        </div>
                                        <input type="text" min="2" max="10" id="label" className="form-control" minLength={1} maxLength={5} value={this.state.label_count_value} onChange={this.handleLabels.bind(this)} onBlur={this.labelValidation} onKeyDown={this.number_press} />
                                        <label className="input-error error_label"></label>
                                    </div>


                                    <div>
                                        <Accordion style={{ textAlign: 'left' }} defaultActiveKey="0">
                                            <div>
                                                <div as={Card.Body} eventkey="0">
                                                    <h5>Answer</h5>
                                                </div>
                                                <Accordion.Collapse eventKey="0">
                                                    <div>
                                                        <div className="form-group">
                                                            <div className="input-group-prepend" style={styles.textbox}>
                                                                <span className="input-group-text" style={styles.text}>Answer Text</span>
                                                            </div>
                                                            <input type="text" className="form-control" id="answer_text" value={this.state.answer_text} maxLength={150} onChange={this.handleAnswer_text.bind(this)} onBlur={this.answerTextValidation} />
                                                            <label className="input-error error_answer_text"></label>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="input-group-prepend" style={styles.textbox}>
                                                                <span className="input-group-text" style={styles.text}>Correct Value*</span>
                                                            </div>
                                                            <input type="text" className="form-control" id="correct_answer" value={this.state.correct_value} minLength={2} maxLength={30} onChange={this.handlecCorrect_value.bind(this)} onBlur={this.correct_answerValidation} />
                                                            <label className="input-error error_correct_answer"></label>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="input-group-prepend" style={styles.textbox}>
                                                                <span className="input-group-text" style={styles.text}>Correct Reinforcement*</span>
                                                            </div>
                                                            <input type="text" className="form-control" id="correct_reinforcement" value={this.state.correct_reinforcement} minLength={2} maxLength={30} onChange={this.handleCorrect_reinforcement.bind(this)} onBlur={this.correct_reinforcementValidation} />
                                                            <label className="input-error error_correct_reinforcement"></label>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="input-group-prepend" style={styles.textbox}>
                                                                <span className="input-group-text" style={styles.text}>Incorrect Reinforcement*</span>
                                                            </div>
                                                            <input type="text" className="form-control" id="incorrect_reinforcement" value={this.state.incorrect_reinforcement} minLength={2} maxLength={30} onChange={this.handleIncorrect_reinforcement.bind(this)} onBlur={this.incorrect_reinforcementValidation} />
                                                            <label className="input-error error_incorrect_reinforcement"></label>
                                                        </div>
                                                    </div>
                                                </Accordion.Collapse>
                                            </div>
                                        </Accordion>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text}>Prompt*</span>
                                        </div>
                                        <input type="text" className="form-control" value={this.state.prompt} id="prompt" minLength={2} maxLength={50} onChange={this.handlePrompt.bind(this)} onBlur={this.promptValidation} />
                                        <label className="input-error error_prompt"></label>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text}>Done text*</span>
                                        </div>
                                        <input type="text" className="form-control" id="done_text" value={this.state.done_text} minLength={2} maxLength={30} onChange={this.handleDoneText.bind(this)} onBlur={this.done_textValidation} />
                                        <label className="input-error error_done_text"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-left" style={{ paddingTop: 10, paddingBottom: 10 }}>
                                <button onClick={this.handleSubmit} className="btn btn-dark" style={{ width: 150 }}>Save</button>
                                <div id="snackbar"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}