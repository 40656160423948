import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Media } from 'react-bootstrap'
import API from '../Components/Global_API'
import { Icon } from 'react-icons-kit'
import { edit } from 'react-icons-kit/ionicons/edit'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import NoInternet from '../Components/NoInternet';

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    upload_button_label: {
        backgroundColor: '#fff',
        marginBottom: 5,
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    }
}


export default class Free_text_assessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unit: this.props.unit,
            title: this.props.data.title,
            done_text: this.props.data.done_text,
            prompt: this.props.data.prompt,
            free_text: '',
            question_id: this.props.data.question_id,
            question_type: this.props.data.question_type,
            course_image: this.props.course_image,
            PrevButton: '',
            current_index: this.props.current_index,
            prev_review: true,
            done_button_status: true
        }
    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }


    handleSubmit = () => {
        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("content").focus();
        } else if (this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 50) {
            document.getElementById("prompt").focus();
        } else if (this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 30) {
            document.getElementById("done_text").focus();
        } else {
            let free_text = JSON.stringify({
                unit_id: this.state.unit.unit_id,
                lesson_id: '0',
                title: this.state.title,
                prompt: this.state.prompt,
                done_text: this.state.done_text,
                free_text_id: this.state.question_id,

            })

            fetch(API + 'edit_free_text.php',
                {
                    method: 'POST',

                    body: free_text
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);

                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }

    handleContentValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_content')[0].innerHTML = "Content is required.";
            document.getElementsByClassName('error_content')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_content')[0].innerHTML = 'Content must be between 2 to 250 character.';
            document.getElementsByClassName('error_content')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_content')[0].innerHTML = "";
            document.getElementsByClassName('error_content')[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    DoneTextValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }

    // number_press = (event) => {
    //     if (!(event.ctrlKey || event.altKey
    //         || (47 < event.keyCode && event.keyCode < 58 && event.shiftKey == false)
    //         || (95 < event.keyCode && event.keyCode < 106)
    //         || (event.keyCode == 8) || (event.keyCode == 9)
    //         || (event.keyCode > 34 && event.keyCode < 40)
    //         || (event.keyCode == 46))) {
    //         event.preventDefault();
    //     }
    // }


    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    // handleChangeMinimum_response = (e) => {
    //     this.setState({ minimum_response: e.target.value });
    // }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleChangeDone_text = (e) => {
        this.setState({ done_text: e.target.value });
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handleFreeText = (e) => {
        this.setState({ free_text: e.target.value })
    }

    handleKeyDown = (e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    handleDisableButton = () => {
        this.setState({ done_button_status: false })
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            return (
                <div className="row text-center">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="card card-sm">
                            <div className="card-content block-xl"  >
                                <div className="body-container" style={styles.body}>
                                    <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }}>
                                        <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                            <div className="row text-center">
                                                <div style={{ width: '10%', paddingLeft: 5 }}>
                                                    <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                                </div>
                                                <div style={{ paddingTop: 15, width: '60%' }}>
                                                    {/* <text style={{color: '#fff',fontSize:20}}></text> */}
                                                    <hr color="#fff" />
                                                </div>
                                                <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                    <div style={styles.dot_btn}>
                                                        <label style={{ color: '#fff', fontSize: 15, padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                        <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ height: 380, paddingTop: 35, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                <h3 className="text-center" style={{ color: '#fff', paddingBottom: 10 }}>{this.state.title}</h3>
                                                {/* <div onClick={() => this.fileInput.click()} style={{height: 100, backgroundColor: '#fff', borderRadius: 4}}>
                                                {this.state.free_text}
                                            </div> */}
                                                <textarea onChange={this.handleFreeText} onClick={this.handleDisableButton} onKeyDown={this.handleKeyDown} style={{ borderRadius: 3, minHeight: 150, backgroundColor: '#ffffff7a', color: '#fff' }} value={this.state.free_text}></textarea>
                                                <Icon icon={edit} onClick={this.handleDisableButton} style={{ position: 'relative', backgroundColor: 'rgba(0,0,0,0.20)', top: -45, width: 40, height: 40, padding: 10, alignSelf: 'flex-end', color: '#fff', borderRadius: 3, right: 5 }} size={20} />
                                            </div>

                                            <div>
                                                <hr color='#fff' />
                                                <div className="text-center">
                                                    <button className="btn" disabled={this.state.done_button_status} onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} style={this.state.done_button_status == true ? styles.disable_done_btn : styles.done_btn}>
                                                        {this.state.done_button_status == true ? this.state.prompt : this.state.done_text}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, width: 363, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                            <div>
                                                <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                    <div className="text-center">
                                                        <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                            Review last slide
                                                    </button>
                                                    </div>
                                                    <div className="text-center">
                                                        <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                            Restart lesson
                                                    </button>
                                                    </div>
                                                    {/* <div className="text-center">
                                                    <button className="btn" style={styles.previewbutton}>
                                                        Exit lesson
                                                    </button>
                                                </div> */}
                                                    <div className="text-center">
                                                        <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                            Cancel
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                                <div className="col-4">
                                    <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                                </div>
                                <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                    <span >Slide {this.props.current_index + 1}</span>
                                </div>
                                <div className="col-4">
                                    <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <div className="card-sm" >
                            <div className="card-content block-xl" style={{ height: 550, overflowY: 'scroll', overflowX: 'scroll' }}>
                                <h5 className="text-left" style={{ paddingBottom: 10 }}>Free Text</h5>
                                <div className="text-left">
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <text className="input-group-text" style={styles.text}>Content*</text>
                                        </div>
                                        <input type="text" className="form-control" id="content" minLength={2} maxLength={250} value={this.state.title} onChange={this.handleChangeTitle} onBlur={this.handleContentValidation} />
                                        <label className={"input-error error_content"}></label>
                                    </div>
                                    {/* <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <text className="input-group-text" style={styles.text}>Minimum Response Lenght</text>
                                    </div>
                                    <input type="text" className="form-control" minLength={2} maxLength={100} value={this.state.minimum_response} onChange={this.handleChangeMinimum_response} onKeyDown={this.number_press} />
                                </div> */}

                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <text className="input-group-text" style={styles.text} >Prompt*</text>
                                        </div>
                                        <input type="text" className="form-control" id="prompt" value={this.state.prompt} minLength={2} maxLength={50} onChange={this.handleChangePrompt} onBlur={this.promptValidation} />
                                        <label className={"input-error error_prompt"}></label>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <text className="input-group-text" style={styles.text} >Done Text*</text>
                                        </div>
                                        <input type="text" className="form-control" id="done_text" value={this.state.done_text} minLength={2} maxLength={30} onChange={this.handleChangeDone_text} onBlur={this.DoneTextValidation} />
                                        <label className={"input-error error_done_text"}></label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                                <button onClick={this.handleSubmit} className="btn btn-dark" style={{ width: 150 }}>Save</button>
                                <div id="snackbar"></div>
                            </div>
                        </div>
                    </div>
                </div>
                // </div>
            )
        }
    }
}