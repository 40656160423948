import React from 'react'
import { Button } from 'react-bootstrap'
const BackgroundColor = require('../../assets/question_bg.jpg')

export default class True_false_detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            todos: [
                {
                    taskID: 1,
                    task: this.props.data.text
                },
            ],
            done_button_name: this.props.data.prompt,
            done_text: this.props.data.done_text,
            done_button_state: true,
            completedTasks: [],
            answer_popup: true,
            selected_answer: '',
            answer_reinforcement: '',
            course_image: this.props.data.course_image,
            description: this.props.data.description,
            title: this.props.data.title,
            draggedTask: {}
        }
       
    }

    handleAnswerpopup = () => {
        this.setState({ answer_popup: false })
        if (this.state.selected_answer == this.props.data.correct_answer) {
            this.setState({ answer_reinforcement: this.props.data.correct_reinforcement })
        } else {
            this.setState({ answer_reinforcement: this.props.data.incorrect_reinforcement })
        }
    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    onMouseUp = (e) => {
        var elmnt = document.getElementById('dragabble_div');
        elmnt.style.display = 'none';
        if (this.state.dropDone) {
            elmnt.style.display = 'none';
        } else {

            var div_true = document.getElementById('div_true');
            var div_false = document.getElementById('div_false');
            div_true.style.backgroundColor = "rgba(255, 255, 255, 0.15)";
            div_false.style.backgroundColor = "rgba(255, 255, 255, 0.15)";



            elmnt.style.display = 'block';
        }
    }
    onDrag = (event, todo) => {
        var elmnt = document.getElementById('dragabble_div');
        elmnt.style.display = 'none';
        event.preventDefault();
        this.setState({
            draggedTask: todo
        });
    }
    onDragOverTrue = (event) => {
        this.setState({ selected_answer: 'true' })
        var div_true = document.getElementById('div_true');
        var div_false = document.getElementById('div_false');
        div_true.style.backgroundColor = "rgba(212, 249, 10, 0.61)";
        div_false.style.backgroundColor = "rgba(255, 255, 255, 0.15)";
        event.preventDefault();
    }

    onDragOverFalse = (event) => {
        this.setState({ selected_answer: 'false' })
        event.preventDefault();
        var div_false = document.getElementById('div_false');
        var div_true = document.getElementById('div_true');
        div_true.style.backgroundColor = "rgba(255, 255, 255, 0.15)";
        div_false.style.backgroundColor = "rgba(212, 249, 10, 0.61)";
    }

    onDropFalse = (event) => {
        const { completedTasks, draggedTask, todos } = this.state;
        this.setState({
            //   completedTasks: [...completedTasks, draggedTask],
            todos: todos.filter(task => task.taskID !== draggedTask.taskID),
            done_button_state: false,
            done_button_name: this.state.done_text,
            dropDone: true
        });
    }

    onDropTrue = (event) => {
        const { completedTasks, draggedTask, todos } = this.state;
        this.setState({
            //   completedTasks: [...completedTasks, draggedTask],
            todos: todos.filter(task => task.taskID !== draggedTask.taskID),
            done_button_state: false,
            done_button_name: this.state.done_text,
            dropDone: true
        });
    }


    render() {
        const { todos, completedTasks } = this.state;
        return (
            <div>
                <div className="col-12" style={{ backgroundColor: '#2bb5fe',backgroundImage: this.state.course_image == '' ? `url(${BackgroundColor})` : `url(${this.state.course_image})`,  backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 500, top: -30, left: -30, margin: 30, visibility: this.state.Instruction_Slide ? "hidden" : "visible", position: 'absolute', padding: 0 }}>
                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 500, paddingTop: 21, paddingLeft: 15, paddingRight: 15 }}>
                        <div className="row text-center">
                            <div style={{ width: '10%', paddingLeft: 5 }}>
                                <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                            </div>
                            <div style={{ paddingTop: 15, width: '60%' }}>
                                {/* <text style={{color: '#fff',fontSize:20}}>Scrollable</text> */}
                                <hr color='#fff' />
                            </div>
                            <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                <div style={styles.dot_btn}>
                                    <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_question_index + 1} / {this.props.que_count}</label>
                                    <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 380, paddingTop: 35, display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
                            <h5 className="text-center" style={{ color: '#fff', overflowWrap: 'anywhere' }}>{this.state.title}</h5>
                            <p className="text-center" style={{ color: '#fff', overflowWrap: 'anywhere' }}>{this.state.description}</p>
                            <div className="text-center">
                                <button className="btn" onClick={() => this.setState({ Instruction_Slide: true })} style={{ backgroundColor: '#fff', marginBottom: 30, color: '#000000', borderWidth: 1, borderColor: '#fff', fontWeight: 'bold' }}> OK I'm ready!</button>
                            </div>
                        </div>
                    </div>

                </div>



                <div style={{ height: 340, fontSize: 15, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div className="row text-center" style={{ paddingTop: 12, position: 'relative' }} >
                        {/* <div className="row text-center" style={{ position: 'relative' }} > */}
                        <div id="div_true" className="col-2" style={{ backgroundColor: 'rgba(255, 255, 255, 0.15)', marginTop: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <text style={{ color: '#fff', position: 'absolute', paddingLeft: 30 }}>True</text>
                            <div
                                style={styles.column}
                                onDrop={event => this.onDropTrue(event)}
                                onDragOver={(event => this.onDragOverTrue(event))}
                            >
                                {completedTasks.map((task, index) =>
                                    <div key={task.taskID}>
                                        {task.task}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-8" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <div style={styles.todos} >
                                    {
                                        todos.map(todo =>
                                            <div
                                                id="dragabble_div"
                                                key={todo.taskID}
                                                draggable
                                                onDragEnd={(event => this.onMouseUp(event))}
                                                onDrag={(event) => this.onDrag(event, todo)}
                                                style={{ backgroundColor: '#fff', color: 'black', padding: 15,  overflowY: 'auto', maxHeight: 200  }}
                                            >
                                                <p style={{ overflowWrap: 'break-word' }}>{todo.task}</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div id="div_false" className="col-2" style={{ backgroundColor: 'rgba(255,255, 255, 0.15)', marginTop: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <text style={{ color: '#fff', position: 'absolute', paddingLeft: 30 }}>False</text>
                            <div
                                style={styles.column}
                                onDrop={event => this.onDropFalse(event)}
                                onDragOver={(event => this.onDragOverFalse(event))}
                            >
                                {completedTasks.map((task, index) =>
                                    <div key={task.taskID}>
                                        {task.task}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>

                <div style={{ position: 'relative' }}>
                    <hr color='#fff' />
                    <div className="text-center">
                        <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleAnswerpopup() }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                            {this.state.done_button_name}
                        </button>
                    </div>
                    <div style={{ backgroundColor: '#fff', visibility: this.state.answer_popup ? "hidden" : "visible", marginTop: -200, position: 'relative', marginLeft: -15, marginRight: -16, padding: 15, borderTop: 'solid', borderTopColor: 'rgba(0,0,0,0.4)', borderTopWidth: 5 }}>
                        <div style={{ height: 85, overflowY: 'auto' }}>
                            <p><strong>{this.state.answer_reinforcement}</strong></p>
                            <div>
                                <p>{this.props.data.answer_text}</p>
                            </div>
                        </div>
                        <hr />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div className="col-6">
                                <Button style={styles.continue_button} onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} block>Continue</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    question_content: {
        backgroundColor: '#34baeb',
        height: 500,
        margin: 30
    },
    edit_btn: {
        backgroundColor: '#223042',
        color: '#fff',
        marginTop: 30
    },
    slider_card: {
        backgroundColor: '#fff',
        paddingBottom: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    column: {
        height: 500,
        width: 100,
    },
    continue_button: {
        borderWidth: 2,
        borderColor: 'rgba(0,0,0,0.2)',
        padding: 10,
        backgroundColor: '#fff',
        color: '#000000'
    },
    todos: {
        height: 100,
        minWidth: 100,
        maxWidth: 300,
        margin: 10
    }

}