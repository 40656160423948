import React from 'react';
import '../Css/Styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ExpandableListFunctionality from './Functionality/ExpandableListFunctionality'
import ScrollableFunctionality from './Functionality/ScrollableFunctionality'
import VideoFunctionality from './Functionality/VideoFunctionality'
import MultipleChoiceFunctionlity from './Functionality/MultipleChoiceFunctionlity'
import SliderFunctionality from './Functionality/SliderFunctionality'
import TrueFalseFunctionality from './Functionality/TrueFalseFunctionality'
import CrosswordFunctionality from './Functionality/CrosswordFunctionality';
import MemoryFunctionality from './Functionality/MemoryFunctionality';
import Exit from './Functionality/Exit';
import Title_screen from './Functionality/Title_screen'
import Circle_Functionlity from './Functionality/Circle_Functionlity';
import Multiple_choice_image from './Functionality/Multiple_choice_image';
import Peer_authoring from './Functionality/Peer_authoring';
import Reorder from './Functionality/Reorder_item';
import Free_text from './Functionality/Free_text';
import ListSlide from './Functionality/ListSlide';
import VimeoVideo from './Functionality/VimeoVideo';
import Drag_to_match from './Functionality/Drag_to_match';
import QuestionPool_Functionality from './Functionality/QuestionPool_Functionality';

export default class Component_Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cmpType: '',
        }
    }

    componentDidMount() {
        this.setState({ 'cmpType': this.props.component_type });
    }
    
    render() {
        if (this.props.component_type == 'expandable') {
            return (<ExpandableListFunctionality nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'scrollable') {
            return (<ScrollableFunctionality nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'video_slide') {
            return (<VideoFunctionality nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'multiple_choice') {
            return (<MultipleChoiceFunctionlity nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'slider') {
            return (<SliderFunctionality nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'true_false') {
            return (<TrueFalseFunctionality nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'crossword') {
            return (<CrosswordFunctionality nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'memory') {
            return (<MemoryFunctionality nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'circle_the_answer') {
            return (<Circle_Functionlity nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'exit_lesson') {
            return (<Exit data={this.props.component_data} nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} />)
        } else if (this.props.component_type == 'start_lesson') {
            return (<Title_screen nextQuestion={this.props.nextQuestion} course_image={this.props.course_image} handleUpdateQuetionData={this.props.handleUpdateQuetionData} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'multiple_choice_image'){
            return(<Multiple_choice_image nextQuestion={this.props.nextQuestion} course_image={this.props.course_image} handleUpdateQuetionData={this.props.handleUpdateQuetionData} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'peer_authoring'){
            return(<Peer_authoring nextQuestion={this.props.nextQuestion} course_image={this.props.course_image} handleUpdateQuetionData={this.props.handleUpdateQuetionData} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'reorder'){
            return(<Reorder nextQuestion={this.props.nextQuestion} course_image={this.props.course_image} handleUpdateQuetionData={this.props.handleUpdateQuetionData} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'list_slide'){
            return(<ListSlide nextQuestion={this.props.nextQuestion} course_image={this.props.course_image} handleUpdateQuetionData={this.props.handleUpdateQuetionData} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'vimeo_video'){
            return(<VimeoVideo nextQuestion={this.props.nextQuestion} course_image={this.props.course_image} handleUpdateQuetionData={this.props.handleUpdateQuetionData} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'free_text'){
            return(<Free_text nextQuestion={this.props.nextQuestion} course_image={this.props.course_image} handleUpdateQuetionData={this.props.handleUpdateQuetionData} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'drag_to_match'){
            return(<Drag_to_match nextQuestion={this.props.nextQuestion} course_image={this.props.course_image} handleUpdateQuetionData={this.props.handleUpdateQuetionData} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'lesson_question_pool'){
            return(<QuestionPool_Functionality question_pool_current_index={this.props.question_pool_current_index} handleQuestionPoolData={this.props.handleQuestionPoolData} nextQuestion={this.props.nextQuestion} course_image={this.props.course_image} handleUpdateQuetionData={this.props.handleUpdateQuetionData} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else {
            return (<div><img src={require('../assets/Loader.gif')} /></div>)
        }
    }
}